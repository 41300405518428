import { CognitoUserPool } from "amazon-cognito-identity-js";


const UserPoolId = process.env.REACT_APP_USER_POOL_ID as string;
const ClientId = process.env.REACT_APP_CLIENT_ID as string;
const poolData = {
  UserPoolId: UserPoolId,
  ClientId: ClientId,
};

const userPool = new CognitoUserPool(poolData);
export default userPool;