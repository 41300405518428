import React, { useContext, ReactElement } from "react";
import { Navigate } from "react-router-dom";
import { AccountContext } from "../components/Admin/Accounts"; // Adjust this path if needed

interface ProtectedRouteProps {
  element: ReactElement; // The component to render if access is allowed
  allowedRoles: string[]; // Array of roles allowed to access this route
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element, allowedRoles }) => {
  const accountContext = useContext(AccountContext);

  if (!accountContext) {
    throw new Error("Account context is not available.");
  }

  const { getSession } = accountContext;

  const [user, setUser] = React.useState<any>(null); // State to hold user data
  const [loading, setLoading] = React.useState(true); // State to manage loading state

  // Fetch user session on component mount
  React.useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const sessionData = await getSession();
        if (sessionData) {
          setUser(sessionData);
        } else {
          setUser(null); // Ensure user is explicitly set to null if no session
        }
      } catch (error) {
        console.error("Error fetching session:", error);
        setUser(null); // Explicitly handle error by setting user to null
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };

    fetchUserSession();
  }, [getSession]);

  // While loading, return a loading indicator or null
  if (loading) {
    return <div>Loading...</div>; // Show a loading indicator
  }

  // Check if the user is authenticated and has the required role
  const userRole = user ? user["custom:role"] : null; // Assuming user roles are stored in a custom attribute

  if (user && allowedRoles.includes(userRole)) {
    return element; // Return the React element directly if access is allowed
  } else {
    // Redirect to the login page if unauthorized or session not found
    return <Navigate to="/" replace />;
  }
};

export default ProtectedRoute;
