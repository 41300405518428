import React from "react";
import { Button, Card, Space, Typography, Divider, message } from "antd";
import {
  KeyOutlined,
  SafetyOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import userPool from "../states/UserPool";
import { useNavigate } from "react-router-dom";

const {Text } = Typography;

interface SettingOptionProps {
  icon: React.ReactElement;
  title: string;
  description: string;
  onClick: () => void;
}

const SettingOption: React.FC<SettingOptionProps> = ({
  icon,
  title,
  description,
  onClick,
}) => (
  <Card
    hoverable
    style={{ width: "100%", marginBottom: "16px" }}
    onClick={onClick}
  >
    <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
      {React.cloneElement(icon, { style: { fontSize: "20px" } })}
      <div style={{ flex: 1 }}>
        <Text strong style={{ fontSize: "16px", display: "block" }}>
          {title}
        </Text>
        <Text type="secondary" style={{ display: "block" }}>
          {description}
        </Text>
      </div>
    </div>
  </Card>
);

interface SettingsProps {
  onSelectComponent: (component: string) => void;
}

const Settings: React.FC<SettingsProps> = ({ onSelectComponent }) => {
  const navigate = useNavigate();

  const onSignOut = () => {
    const user = userPool.getCurrentUser();

    if (user) {
      user.signOut();
      message.success({
        content: "You have signed out successfully!",
        style: { fontWeight: 500 },
      });
      navigate("/");
    } else {
      message.error({
        content: "No user is currently signed in.",
        style: { fontWeight: 500 },
      });
    }
  };

  const settingsOptions: SettingOptionProps[] = [
    {
      icon: <KeyOutlined style={{ color: "#1890ff" }} />,
      title: "Change Password",
      description: "Update your account password and security settings",
      onClick: () => onSelectComponent("ChangePassword"),
    },

    {
      icon: <SafetyOutlined style={{ color: "#722ed1" }} />,
      title: "MFA Setup",
      description:
        "Configure multi-factor authentication for enhanced security",
      onClick: () => onSelectComponent("MFASetup"),
    },
  ];

  return (
    <div
      style={{
        maxWidth: "672px",
        margin: "0 auto",
        padding: "24px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "12px",
          marginBottom: "24px",
        }}
      ></div>


      <Space direction="vertical" style={{ width: "100%" }}>
        {settingsOptions.map((option, index) => (
          <SettingOption key={index} {...option} />
        ))}
      </Space>

      <Divider style={{ margin: "24px 0" }} />

      <Button
        danger
        type="primary"
        icon={<LogoutOutlined />}
        onClick={onSignOut}
        size="large"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          backgroundColor: "#dd2839" 
        }}
      >
        Sign Out
      </Button>
    </div>
  );
};

export default Settings;
