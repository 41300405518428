import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import LogIn from "./pages/LogIn";
import AgentDashboard from "./pages/AgentDashboard";
import AdminDashboard from "./pages/AdminDashboard";
import RegisterUser from "./components/registerUsers/RegisterUser";
import { Account } from "./components/Admin/Accounts";
import MFA from "./components/MFA";
import ForceChangePassword from "./components/forcePasswordChange/ForcePasswordChange";
import Status from "./components/Status";
import UpdateUserDetails from "./components/Admin/UpdateUserDetails";
import ChangePassword from "./components/ChangePassword";
import ListUser from "./components/Admin/UserList";
import ForgetPassword from "./components/ForgotPassword";
import ProtectedRoute from "./components/ProtectedRoute"; // Import the ProtectedRoute component

function App() {
  return (
    <Router>
      <Account>
        <Routes>
          <Route path="/" element={<LogIn />} />

          {/* Protected Agent Screens */}
          <Route
            path="/agent-dashboard"
            element={
              <ProtectedRoute
                element={<AgentDashboard />}
                allowedRoles={["agent"]}
              />
            }
          />

          {/* Protected Admin Screens */}
          <Route
            path="/admin-dashboard"
            element={
              <ProtectedRoute
                element={<AdminDashboard />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route
            path="/register-user"
            element={
              <ProtectedRoute
                element={<RegisterUser />}
                allowedRoles={["admin"]}
              />
            }
          />
          <Route path="/mfa-setup" element={<MFA />} />
          <Route
            path="/force-change-password"
            element={<ForceChangePassword />}
          />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/status" element={<Status />} />
          <Route path="/update-details" element={<UpdateUserDetails />} />
          <Route path="/list-user" element={<ListUser />} />
          <Route path="/forgot-password" element={<ForgetPassword />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Account>
    </Router>
  );
}

export default App;
