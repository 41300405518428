import React, { useContext, useEffect } from "react";
import { Form, Input, Button, Select, message } from "antd";
import { CognitoUserAttribute } from "amazon-cognito-identity-js";
import { AccountContext } from "./Accounts";

const { Option } = Select;

const UpdateUserProfile: React.FC = () => {
  const [form] = Form.useForm(); // Create a form instance
  const accountContext = useContext(AccountContext);

  // Check if accountContext is undefined
  if (!accountContext) {
    throw new Error("Account context is not available.");
  }

  const { getSession } = accountContext;

  // Fetch and set user attributes when the component mounts
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const { user } = await getSession();
        const attributes = await new Promise<{ [key: string]: string }>(
          (resolve, reject) => {
            user.getUserAttributes((err: any, attributes: any) => {
              if (err) {
                reject(err);
              } else {
                const results: { [key: string]: string } = {};
                if (attributes)
                  for (let attribute of attributes) {
                    const { Name, Value } = attribute;
                    results[Name] = Value;
                  }
                resolve(results);
              }
            });
          }
        );

        // Use setFieldsValue to update form fields
        form.setFieldsValue({
          firstName: attributes["given_name"],
          lastName: attributes["family_name"],
          role: attributes["custom:role"],
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [getSession, form]); // Include form in the dependency array

  const onFinish = async (values: any) => {
    const { firstName, lastName, role } = values;

    try {
      const { user } = await getSession();

      const attributes: CognitoUserAttribute[] = [
        new CognitoUserAttribute({ Name: "given_name", Value: firstName }), // First Name
        new CognitoUserAttribute({ Name: "family_name", Value: lastName }), // Last Name
        new CognitoUserAttribute({ Name: "custom:role", Value: role }), // Role
      ];

      user.updateAttributes(attributes, (err: any, results: any) => {
        if (err) {
          console.error(err);
          message.error("Failed to update profile.");
        } else {
          console.log(results); // Log the results
          message.success("User profile updated successfully!");
        }
      });
    } catch (error) {
      console.error("Error updating profile:", error);
      message.error("Error updating profile. Please try again.");
    }
  };

  return (
    <div>
      <h2>Update User Profile</h2>
      <Form form={form} onFinish={onFinish} layout="vertical">
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "Please input your first name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: "Please input your last name!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please select your role!" }]}
        >
          <Select>
            <Option value="admin">Admin</Option>
            <Option value="agent">Agent</Option>
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Update Profile
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateUserProfile;
