import React from 'react';
import { Layout, Typography } from 'antd';
import PasswordChangeForm from './ForcePasswordChangeForm';

const { Title, Paragraph } = Typography;

const App: React.FC = () => {
  return (
    <Layout style={{ padding: '50px' }}>
      <Layout.Content>
        <Title level={2}>Change Password</Title>
        <Paragraph>
          For your security, you are required to change your temporary password to a new strong password.
          Please enter your temporary password and then create a new password that meets the specified requirements.
          Make sure your new password is at least 8 characters long, includes uppercase and lowercase letters, a number,
          and a special character. Finally, confirm your new password by re-entering it in the provided field.
        </Paragraph>
        <PasswordChangeForm />
      </Layout.Content>
    </Layout>
  );
};

export default App;
