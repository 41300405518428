import react from 'react';
import AgentMenuBar from '../components/AgentMenuBar';

const AgentDashboard = () => {
    return (
        <div>
            <AgentMenuBar />
        </div>
    );
}

export default AgentDashboard;