import React, { useState, useContext } from "react";
import { Form, Input, Button, Select, message } from "antd";
import { createUser } from "../../api/user";
import { AccountContext } from "../Admin/Accounts"; // Adjust this import according to your file structure

const { Option } = Select;

const RegisterUserForm: React.FC = () => {
  const accountContext = useContext(AccountContext); // Access the AccountContext
  const [loading, setLoading] = useState(false); // Loading state

  // Check if accountContext is available
  if (!accountContext) {
    throw new Error("Account context is not available.");
  }

  const { getSession, getUserId } = accountContext;

  const onFinish = async (values: any) => {
    //console.log('Received values:', values);
    setLoading(true); // Set loading to true
    try {
      const userID = await getUserId();
      // Get the session token
      const { token } = await getSession();

      const updatedValues = {
        ...values, // Spread existing form values
        userID, // Add the user ID
      };

      // Call the createUser function with the form values and token
      await createUser(updatedValues, token);
      /* console.log('User created successfully:', response); */
      message.success("User Created Successfully");
      // Optionally navigate to another page or show success message
    } catch (error) {
      /* onsole.error('Error during user creation:', error); */
      message.error("Failed to create user, Please contact support");
    } finally {
      setLoading(false); // Reset loading state after the operation is complete
    }
  };

  return (
    <Form
      name="register"
      onFinish={onFinish}
      layout="vertical"
      initialValues={{ role: "agent" }} // Set default role
      style={{ backgroundColor: "" }}
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          { required: true, message: "Please input your email!" },
          { type: "email", message: "Please enter a valid email!" },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="firstName"
        label="First Name"
        rules={[{ required: true, message: "Please input your first name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="lastName"
        label="Last Name"
        rules={[{ required: true, message: "Please input your last name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="role"
        label="Role"
        rules={[{ required: true, message: "Please select your role!" }]}
      >
        <Select>
          <Option value="admin">Admin</Option>
          <Option value="agent">Agent</Option>
        </Select>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          style={{ backgroundColor: "#dd2839" }}
          htmlType="submit"
          loading={loading}
          disabled={loading}
        >
          {loading ? "Registering..." : "Register"}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RegisterUserForm;
