import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from './Admin/Accounts';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

const Settings: React.FC = () => {
  const [status, setStatus] = useState<boolean>(false); // Specify the state type

  const accountContext = useContext(AccountContext);

  // Check if the context is available
  if (!accountContext) {
    throw new Error('AccountContext must be used within an Account provider');
  }

  const { getSession, logout } = accountContext;

  useEffect(() => {
    const fetchSession = async () => {
      try {
        const session: CognitoUserSession = await getSession();
        console.log('Session:', session);
        setStatus(true);
      } catch (error) {
        console.error('Failed to fetch session:', error);
      }
    };

    fetchSession();
  }, [getSession]);

  return (
    <div>
      {status ? (
        <div>
          You are logged in.
          <button onClick={logout}>Logout</button>
        </div>
      ) : (
        'Please login below.'
      )}
    </div>
  );
};

export default Settings;
