import React, { useState } from 'react';
import {
  TableOutlined,
  FormOutlined,
  BookOutlined,
  BarsOutlined,
  SettingOutlined
} from '@ant-design/icons';
import { Col, MenuProps, Typography } from 'antd';
import { Layout, Menu } from 'antd';
import styled from 'styled-components';
import logo from '../../assets/ubs-full-logo.png';

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  .ant-layout-sider-trigger {
    background-color: #DD2839; // Ensure this is a valid color
    flex:None
  }
`;

const StyledMenu = styled(Menu)`
  .ant-menu-item-selected {
    background-color: #E60000;
    color: white;
  }
  .ant-menu-item {
    color: white;
  }
`;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('View Recent Activities', '1', <TableOutlined />),
  getItem('Create Account', '2', <FormOutlined />),
  getItem('Manage Account', '3', <BookOutlined />),
  getItem('View Activities', '4', <BarsOutlined />),
  getItem('Settings', '5', <SettingOutlined />),
];

interface AdminMenuBarProps {
  onSelectComponent: (component: string) => void; // Prop for handling component selection
}

const AdminMenuBar: React.FC<AdminMenuBarProps> = ({ onSelectComponent }) => {
  const [selectedKey, setSelectedKey] = useState<string>('1'); // State for selected key

  const handleSelectLogo = () => {
    setSelectedKey('1');
    onSelectComponent(''); // Reset selection or handle accordingly
  };

  const onMenuSelect = (e: { key: string }) => {
    setSelectedKey(e.key);
    switch (e.key) {
      case '1':
        onSelectComponent('RecentActivities'); // Update to your desired component
        break;
      case '2':
        onSelectComponent('RegisterUser');
        break;
      case '3':
        onSelectComponent('ListUser'); // Adjust based on your components
        break;
      case '4':
        onSelectComponent('ActivityPage'); // Adjust based on your components
        break;
      case '5':
        onSelectComponent('Settings'); // Adjust based on your components
        break;
      default:
        onSelectComponent('RecentActivities');
    }
    
  };

  return (
    <Layout style={{ minHeight: '100vh',flexGrow: 0, flexShrink: 0, flexBasis: 'auto'}}>
      <StyledSider 
        style={{ background:'black', padding:'0px'}}
      >
        <Col>
          <img
            src={logo}
            style={{ height: '33px', width: 'auto', margin:'45px 63px 45px 30px' }}
            onClick={handleSelectLogo}
          />
          <Typography
            style={{ color:'white', marginLeft:'30px', marginBottom:'5px', fontWeight: 'bold' }}
          >
            Admin Portal
          </Typography>
          <StyledMenu 
            theme="dark" 
            selectedKeys={[selectedKey]}
            onSelect={onMenuSelect}
            mode="inline" 
            items={items} 
            style={{ background:'black' }}
          />
        </Col>
      </StyledSider>
    </Layout>
  );
};

export default AdminMenuBar;
