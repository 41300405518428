import { Col, Row, Typography } from 'antd';
import React, { useState } from 'react';
import {
    UpOutlined
  } from '@ant-design/icons';

interface PageHeaderProps {
    title: string;
    user: string;
}

const PageHeader = (props: PageHeaderProps) => {
    return (
        <Col style={{ height: '100px', width:'100%', backgroundColor:'white', padding:'60px 40px' }}>
            <Row justify='space-between'>
                <Col>
                    <Typography
                        style={{
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                    >
                        {props.title}
                    </Typography>
                </Col>
                <Col>
                    <Row>
                        <Typography
                            style={{ fontSize:'14px', marginRight: '5px' }}
                        >
                            {props.user}
                        </Typography>
                        <UpOutlined/>
                    </Row>
                </Col>
            </Row>
        </Col>
    )
}

export default PageHeader;