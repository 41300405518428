import React, { useEffect, useState, useContext } from "react";
import { AccountContext } from "./Admin/Accounts";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { 
  Button, 
  Input, 
  Card, 
  Typography, 
  Alert, 
  Space, 
  Divider,
  Form,
  message
} from "antd";
import { 
  QrcodeOutlined, 
  SecurityScanOutlined, 
  LockOutlined 
} from "@ant-design/icons";

const { Title, Text } = Typography;

interface SessionData extends CognitoUserSession {
  mfaEnabled: boolean;
  user: any;
  headers: HeadersInit;
  accessToken: string | { jwtToken: string };
}

const MFAComponent: React.FC = () => {
  const [userCode, setUserCode] = useState<string>("");
  const [enabled, setEnabled] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm();

  const accountContext = useContext(AccountContext);

  if (!accountContext) {
    throw new Error("Account context is not available.");
  }

  const { getSession } = accountContext;

  useEffect(() => {
    getSession().then((session: SessionData) => {
      setEnabled(session.mfaEnabled);
    });
  }, [getSession]);

  const API_URL = `${process.env.REACT_APP_API_URL}/mfa` as string;
  const API_KEY = process.env.REACT_APP_API_KEY as string;

  const getQRCode = () => {
    setLoading(true);
    getSession().then(({ accessToken, headers }: SessionData) => {
      if (typeof accessToken !== "string") {
        accessToken = accessToken.jwtToken;
      }

      headers = {
        ...headers,
        "x-api-key": API_KEY,
      };

      const uri = `${API_URL}?accessToken=${accessToken}`;

      fetch(uri, { headers })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((errorData) => {
              console.error("API Error:", errorData);
              throw new Error(`API Error: ${response.status}`);
            });
          }
          return response.json();
        })
        .then((jsonData) => {
          setImage(jsonData);
        })
        .catch((error) => {
          console.error(error);
          message.error("Failed to generate QR code");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleEnableMFA = (values: { code: string }) => {
    setLoading(true);

    getSession().then(({ user, accessToken, headers }: SessionData) => {
      headers = {
        ...headers,
        "x-api-key": API_KEY,
      };
      
      if (typeof accessToken !== "string") {
        accessToken = accessToken.jwtToken;
      }

      const uri = `${API_URL}?accessToken=${accessToken}&userCode=${values.code}`;

      fetch(uri, {
        method: "POST",
        headers,
      })
        .then((data) => data.json())
        .then((result) => {
          console.log(result);
          if (result.Status && result.Status === "SUCCESS") {
            setEnabled(true);
            const settings = {
              PreferredMfa: true,
              Enabled: true,
            };
            user.setUserMfaPreference(null, settings, () => {});
            message.success("MFA enabled successfully");
          } else {
            if (result.errorType === "EnableSoftwareTokenMFAException") {
              message.error("Incorrect 6-digit code!");
            } else if (result.errorType === "InvalidParameterException") {
              message.error("Please provide a 6-digit number");
            }
          }
        })
        .catch((error) => {
          console.error(error);
          message.error("Failed to enable MFA");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  const handleDisableMFA = (values: { code: string }) => {
    setLoading(true);

    getSession().then(({ headers, accessToken }: SessionData) => {
      if (typeof accessToken !== "string") {
        accessToken = accessToken.jwtToken;
      }

      headers = {
        ...headers,
        "x-api-key": API_KEY,
      };
      
      const uri = `${API_URL}?accessToken=${accessToken}&userCode=${values.code}`;

      fetch(uri, {
        method: "DELETE",
        headers,
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.Status === "SUCCESS") {
            setEnabled(false);
            setImage("");
            message.success("MFA disabled successfully");
          }
        })
        .catch((error) => {
          message.error("Failed to disable MFA");
        })
        .finally(() => {
          setLoading(false);
        });
    });
  };

  return (
    <Card style={{ maxWidth: 600, margin: "48px auto", padding: 24 }}>
      <Space direction="vertical" style={{ width: "100%" }} size="large">
        <div style={{ textAlign: "center" }}>
          <SecurityScanOutlined style={{ fontSize: 48, color: "#1890ff", marginBottom: 16 }} />
          <Title level={2} style={{ margin: 0 }}>
            Multi-Factor Authentication
          </Title>
        </div>

        <Divider />

        {enabled ? (
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <Alert
              message="MFA is Enabled"
              description="Your account is protected with multi-factor authentication."
              type="success"
              showIcon
            />
            <Form form={form} onFinish={handleDisableMFA}>
              <Space direction="vertical" style={{ width: "100%" }} size="middle">
                <Form.Item
                  name="code"
                  rules={[
                    { required: true, message: "Please enter the 6-digit code" },
                    { len: 6, message: "Code must be 6 digits" }
                  ]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    placeholder="Enter 6-digit code"
                    maxLength={6}
                  />
                </Form.Item>
                <Button 
                  type="primary" 
                  danger 
                  htmlType="submit"
                  loading={loading}
                  block
                  style={{backgroundColor: "#dd2839" }}
                >
                  Disable MFA
                </Button>
              </Space>
            </Form>
          </Space>
        ) : image ? (
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <div style={{ textAlign: "center" }}>
              <Title level={4}>Scan this QR Code</Title>
              <div style={{ 
                padding: 24, 
                background: "#f5f5f5", 
                borderRadius: 8,
                display: "inline-block"
              }}>
                <img src={image} alt="QR Code" style={{ maxWidth: 200 }} />
              </div>
              <Text type="secondary" style={{ display: "block", margin: "16px 0" }}>
                Scan the QR code with your authenticator app and enter the 6-digit code below
              </Text>
            </div>
            <Form form={form} onFinish={handleEnableMFA}>
              <Space direction="vertical" style={{ width: "100%" }} size="middle">
                <Form.Item
                  name="code"
                  rules={[
                    { required: true, message: "Please enter the 6-digit code" },
                    { len: 6, message: "Code must be 6 digits" }
                  ]}
                >
                  <Input
                    prefix={<LockOutlined />}
                    placeholder="Enter 6-digit code"
                    maxLength={6}
                  />
                </Form.Item>
                <Button 
                  type="primary" 
                  htmlType="submit"
                  loading={loading}
                  block
                >
                  Confirm Code
                </Button>
              </Space>
            </Form>
          </Space>
        ) : (
          <Space direction="vertical" style={{ width: "100%" }} size="middle">
            <Alert
              message="Enhance Your Security"
              description="Protect your account with multi-factor authentication for an extra layer of security."
              type="info"
              showIcon
            />
            <Button 
              type="primary" 
              icon={<QrcodeOutlined />}
              onClick={getQRCode}
              loading={loading}
              block

            >
              Enable MFA
            </Button>
          </Space>
        )}
      </Space>
    </Card>
  );
};

export default MFAComponent;