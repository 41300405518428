import React, { useEffect, useState, useContext } from "react";
import { AccountContext } from "./Accounts";
import { Table, Input, Button } from "antd";
import { fetchAdminLogs, fetchUsers } from "../../api/user";
import Loading from "../Loading";
import { SearchOutlined } from "@ant-design/icons";
import { User, Attribute } from "../../userTypes";
import { AdminLog } from "../../userTypes";

interface AdminLogsListProps {
  showPagination?: boolean;
  pageSize?: number;
}

const AdminLogsList: React.FC<AdminLogsListProps> = ({
  showPagination = false,
  pageSize = 5,
}) => {
  const accountContext = useContext(AccountContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [adminLogs, setAdminLogs] = useState<AdminLog[]>([]);
  const [filteredLogs, setFilteredLogs] = useState<AdminLog[]>([]);
  const [adminIdSearch, setAdminIdSearch] = useState<string>("");
  const [users, setUsers] = useState<User[]>([]);

  if (!accountContext) {
    throw new Error("Account context is not available.");
  }

  const { getSession } = accountContext;

  const getAttributeValue = (attributes: Attribute[], name: string) => {
    const attribute = attributes.find((attr) => attr.Name === name);
    return attribute ? attribute.Value : "";
  };

  const getUserName = (userId: string): string => {
    const user = users.find((user) => user.Username === userId);
    if (!user) return userId;
    return getAttributeValue(user.Attributes, "given_name");
  };

  const getActionDescription = (action: string, userId: string): string => {
    const userName = getUserName(userId);
    switch (action.toUpperCase()) {
      case "CREATE":
        return `Created account for ${userName}`;
      case "UPDATE":
        return `Updated ${userName}'s Profile Details`;
      case "DELETE":
        return `Disabled ${userName}'s account`;
      default:
        return action;
    }
  };

  const fetchLogs = async () => {
    try {
      const session = await getSession();

      if (!session) {
        throw new Error("Session is undefined");
      }

      let { token } = session;
      if (typeof token !== "string") {
        token = token.jwtToken;
      }

      setLoading(true);

      // Fetch users
      const fetchedUsers = await fetchUsers(token);
      console.log(fetchedUsers);
      setUsers(fetchedUsers);

      // Fetch logs
      const logs = await fetchAdminLogs(token);
      const logsArray = Array.isArray(logs) ? logs : [logs];
      setAdminLogs(logsArray);
      setFilteredLogs(logsArray);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  const handleSearch = () => {
    if (!adminIdSearch.trim()) {
      setFilteredLogs(adminLogs);
    } else {
      const searchTerm = adminIdSearch.toLowerCase();
      const filtered = adminLogs.filter((log) => {
        const adminName = getUserName(log.admin_id).toLowerCase();
        const targetUserName = getUserName(log.user_id).toLowerCase();
        return (
          adminName.includes(searchTerm) || targetUserName.includes(searchTerm)
        );
      });
      setFilteredLogs(filtered);
    }
  };

  const formatDate = (dateTimeStr: string) => {
    const date = new Date(dateTimeStr);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const formatTime = (dateTimeStr: string) => {
    const date = new Date(dateTimeStr);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  const columns = [
    {
      title: "Admin",
      key: "admin",
      width: "20%",
      render: (record: AdminLog) => (
        <div>
          <div className="font-medium">{getUserName(record.admin_id)}</div>
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "40%",
      render: (record: AdminLog) => (
        <div>{getActionDescription(record.action, record.user_id)}</div>
      ),
    },
    {
      title: "Date",
      dataIndex: "action_time",
      key: "date",
      width: "20%",
      render: (action_time: string) => formatDate(action_time),
    },
    {
      title: "Time",
      dataIndex: "action_time",
      key: "time",
      width: "20%",
      render: (action_time: string) => (
        <span style={{ fontFamily: "monospace" }}>
          {formatTime(action_time)}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          margin: "40px 85px",
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        {showPagination && (
          <div className="mb-4 flex items-center gap-2">
            <Input
              placeholder="Search by admin or user name"
              value={adminIdSearch}
              onChange={(e) => {
                setAdminIdSearch(e.target.value);
                if (!e.target.value.trim()) {
                  setFilteredLogs(adminLogs);
                }
              }}
              style={{ maxWidth: "300px" }}
              onPressEnter={handleSearch}
            />
            <Button
              type="primary"
              icon={<SearchOutlined />}
              onClick={handleSearch}
            >
              Search
            </Button>
          </div>
        )}

        {loading ? (
          <Loading />
        ) : (
          <Table
          dataSource={showPagination ? filteredLogs : filteredLogs.slice(0, pageSize)}
            columns={columns}
            rowKey="id"
            pagination={
              showPagination
                ? {
                    pageSize: pageSize,
                    showTotal: (total) => `Total ${total} items`,
                  }
                : false
            }
          />
        )}
      </div>
    </div>
  );
};

export default AdminLogsList;
