import PageHeader from '../PageHeader';
import { Col, message, Row, Space, Table, Tag, Spin } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from '../DataTable';
import { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { AccountContext } from "../Admin/Accounts";
import { getSecret } from '../../AwsSecrets';
import constructWithOptions from 'styled-components/dist/constructors/constructWithOptions';

interface TransactionRecord {
  id: string;
  client_id: string;
  transaction_type: string;
  amount: number;
  transaction_date: string;
  status: string;
}

  const capitalizeTitle = (str: string) =>
    str
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase());

const ViewTransactions = () => {
  const [dataSource, setDataSource] = useState<TransactionRecord[]>([]);
  const [columns, setColumns] = useState<ColumnsType<TransactionRecord>>([]);
  const accountContext = useContext(AccountContext);
  const [token, setToken] = useState<string | null>(null);
  const [agentId, setAgentId] = useState<string | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const [firstName, setFirstName] = useState<string | null>(null);
  const [lastName, setLastName] = useState<string | null>(null);
  // const [baseUrl, setBaseUrl] = useState<string>('');
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    console.log("Base URL:", baseUrl); // Add this to verify your environment variable
  }, []);
  const testApiConnection = async () => {

    const authToken = 'eyJraWQiOiJDbWFWNlVPOEVDcnJOejNFV0lnVDNrXC96M2tnU2pkQVlDWjRNUmVWWVRhOD0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiJlOTlhNDU3Yy0wMGMxLTcwNjItZDg2NC0wNTg0ZjZmMjM3ZmEiLCJjb2duaXRvOmdyb3VwcyI6WyJhZ2VudC1ncm91cCJdLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmFwLXNvdXRoZWFzdC0xLmFtYXpvbmF3cy5jb21cL2FwLXNvdXRoZWFzdC0xX21seGt5eUpPVCIsImNvZ25pdG86dXNlcm5hbWUiOiJlOTlhNDU3Yy0wMGMxLTcwNjItZDg2NC0wNTg0ZjZmMjM3ZmEiLCJnaXZlbl9uYW1lIjoiQWdlbnQiLCJvcmlnaW5fanRpIjoiNjRjMTJmYWYtMWI5Zi00ZDVjLWE4Y2ItNDg0Yjk1ZTM5MTdmIiwiYXVkIjoiN3FoN2FuNDZtcm5qNHY1ZTdzdjNoY212Nm4iLCJldmVudF9pZCI6ImNmZmE1N2E0LWY0MTUtNDdlOC05NDE1LTczMGE2NjJhNDM5MyIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNzMxNzY1NDU3LCJleHAiOjE3MzE3NzI5OTUsImN1c3RvbTpyb2xlIjoiYWdlbnQiLCJpYXQiOjE3MzE3NjkzOTUsImZhbWlseV9uYW1lIjoiQWNjb3VudCIsImp0aSI6ImRlYjk3N2Q5LTg1NmItNDJiNy1hYzQ1LTAwZGFjNTI2NWVmNyIsImVtYWlsIjoiYWdlbnRAZ21haWwuY29tIn0.EnwJ8DkOUUHLafOaw_f8sBDW3G1pKjCxIj5FoohoYoDWoda4Lau6T4etnwR0VjRa-VbLOAK263_-r5tR_2Uw5KTGS9qbx8Cw90BB0aJfuXMHH24VcqVNsfx6gs_sUPQ1fX5ypUazCeLb-HUw5Xz7lhQ4RXiLJGMKVvGDOczXihYvY1MBWTyVgIU2oQZpHfWHVdCbkRsvLbQdCI3o9ZDsikoWd0rTXvY1JYl6Wl2TYxhlwA7iiE4uBBnYekgReusuINQ3iQMsrC55OcAcUsR1N1SJ2gv1po4U2b64yg4bi5mAD0k1s1uI_xmWAkEy85hG24DjFHBOIwlV3U792iyDSQ';
    
    try {
      console.log("trying now///");
      const response = await axios({
        method: 'get',
        url: `${baseUrl}/transactions`,
        headers: {
          'Authorization': `Bearer ${authToken}`,
          "client_id": '0e158825-601e-455f-a7d2-86e4d8ec3700',
          "Content-Type": "application/json",
        },
      });
      
      console.log('Response:', response.data);
    } catch (error) {
      console.error('Error:', error);
    }
  };
//   useEffect(() => {
//     const initializeConfig = async () => {
//         try {
//             // const secrets = await getSecret();
//             // setBaseUrl(secrets['REACT_APP_BASE_URL']);
//             // setBaseUrl(${BASE_URL});
//         } catch (error) {
//             console.error('Failed to initialize configuration:', error);
//             message.error('Failed to initialize application configuration');
//         } finally {
//             setInitializing(false);
//         }
//     };

//       initializeConfig();
//   }, []);
 
//       // getAuthToken();

//   useEffect(() => {
//       const fetchData = async () => {
//         if (!accountContext?.getSession) {
//           return;
//         }
//           const session = await accountContext.getSession();

//           //get token
//           const authToken = session.token;
//           console.log(authToken);

//           //Get agent Id
//         const agentId = await accountContext.getUserId();
//         setAgentId(agentId);
//         // setToken(authToken);
//         // console.log(authToken)

//         message.error("Failed to get authentication token");

//         if (!authToken) {
//           console.error('No token found');
//           return;
//         }
//         // console.log(authToken);
//         try {
//           // console.log(`${baseUrl}/transactions/`);
//           const response = await axios.get<TransactionRecord[]>(`${baseUrl}/transactions`,
//           { headers: {
//             'Authorization': `${authToken}`,
//             // "Access-Control-Allow-Origin": "*", // Required for CORS support to work
//             // "Access-Control-Allow-Credentials": true,
//             // 'client_id': '0e158825-601e-455f-a7d2-86e4d8ec3700',
//           },
//           data: {
//             'client_id': '0e158825-601e-455f-a7d2-86e4d8ec3700',
//           },});
//           console.log("we are here");
//           const data = response.data;
//           console.log(data);
  
//           setDataSource(data);
  
//           // Define table columns dynamically
//           const dynamicColumns = Object.keys(data[0] || {}).map((key) => ({
//             title: capitalizeTitle(key),
//             dataIndex: key,
//             key,
//           }));
//           setColumns(dynamicColumns);
//         } catch (error) {
//           console.error("Error fetching data:", error);
//           message.error("Failed to fetch transaction data");
//         } finally {
//           setLoading(false);
//         }
//       };
  
//       fetchData();
//     }, [accountContext]);

//   // useEffect(() => {
//   //   // Fetch data from backend
    
//   // }, [token, baseUrl, clientId]);

//   const capitalizeTitle = (str: string) =>
//     str
//       .toLowerCase()
//       .replace(/_/g, " ")
//       .replace(/\b\w/g, (char) => char.toUpperCase());

  return (
    <div>
      <h2>Transaction Records</h2>
      <button onClick={testApiConnection}>Test API Connection</button>
      {loading ? (
        <Spin size="large" />
      ) : (
        <Table
          dataSource={dataSource}
          columns={columns}
          rowKey="id" // Ensure unique row keys
          pagination={{ pageSize: 10 }}
        />
      )}
    </div>
  );
};

export default ViewTransactions;