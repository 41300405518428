import PageHeader from '../PageHeader';
import { Button, Card, Col, DatePicker, Input, message, Modal, Row, Select, Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import InputField from '../InputField';
import type { DatePickerProps } from 'antd';
import styled from 'styled-components';
import successLogo from "../../assets/success-logo.png";
import failLogo from "../../assets/fail-logo.png";
import axios from 'axios';
import { AccountContext } from "../Admin/Accounts";

const StyledCard = styled(Card) `
    .ant-card-body {
        padding: 38px;
    }
`

const CreateClientProfile = () => {
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isFailModalOpen, setIsFailModalOpen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState<string | null>(null);
    const [gender, setGender] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [country, setCountry] = useState('Singapore');
    const [postalCode, setPostalCode] = useState('');
    const accountContext = useContext(AccountContext);
    const [token, setToken] = useState<string | null>(null);
    const [agentId, setAgentId] = useState<string | null>(null);
    const [firstNameError, setFirstNameError] = useState('');
    const [lastNameError, setLastNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [addressError, setAddressError] = useState('');
    const [cityError, setCityError] = useState('');
    const [stateError, setStateError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [postalCodeError, setPostalCodeError] = useState('');
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    useEffect(() => {
        const getAuthToken = async () => {
          if (!accountContext?.getSession) {

            return;
          }
    
          try {

            const session = await accountContext.getSession();

            //get token
            const authToken = session.token;

            //Get agent Id
            const agentId = await accountContext.getUserId();
            setAgentId(agentId);
            setToken(authToken);
            console.log(authToken)
          } catch (error) {
            console.error("Error getting auth token:", error);

            message.error("Failed to get authentication token");
          } finally {
 
          }
        };
    
        getAuthToken();
      }, [accountContext]);

    const isFormValid = () => {
        return (
            firstName && lastName && dateOfBirth && gender && email && phoneNumber && 
            address && city && state && postalCode
        );
    }

    const handleDobChange: DatePickerProps['onChange'] = (date, dateString) => {
        setDateOfBirth(dateString as string || null);
    };

    const handleGenderChange = (val: string) => {
        setGender(val);
    }

    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        if (value.length < 2 || value.length > 50){
            setFirstNameError('Must be at more than 2 characters and less than 50 characters');
            return;
        } else if (!/^[a-zA-Z\s]*$/.test(value))
        if (event.target.value.length >= 2 && event.target.value.length <= 50){
            setFirstNameError('Must only contain alphabets and spaces');
            return;
        } 
        setFirstName(value);
        setFirstNameError('');
    }
    
    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value

        if (value.length < 2 || value.length > 50){
            setLastNameError('Must be at more than 2 characters and less than 50 characters');
            return;
        } else if (!/^[a-zA-Z\s]*$/.test(value))
        if (event.target.value.length >= 2 && event.target.value.length <= 50){
            setLastNameError('Must only contain alphabets and spaces');
            return;
        } 
        setLastName(event.target.value);
        setLastNameError('');
    }
    
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (!value.includes('@')){
            setEmailError('Must be a valid email address');
            return
        }
        setEmail(event.target.value);
        setEmailError('')
    }
    
    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (!value.includes('+') || value.length < 10 || value.length > 15) {
            setPhoneNumberError('Must be a valid phone number. E.g. +1234567890');
            return;
        }
        setPhoneNumber(event.target.value);
        setPhoneNumberError('')
    }
    
    const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (value.length < 5 || value.length > 100){
            setAddressError('Must be more than 5 characters and less than 100 characters');
            return;
        }
        setAddress(event.target.value);
        setAddressError('');
    }
    
    const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (value.length < 5 || value.length > 100){
            setCityError('Must be more than 2 characters and less than 50 characters');
            return;
        }
        setCity(event.target.value);
        setCityError('');
    }
    
    const handleStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (value.length < 5 || value.length > 100){
            setStateError('Must be more than 2 characters and less than 50 characters');
            return;
        }
        setState(event.target.value);
        setStateError('');
    }
    
    const handleCountryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (value.length < 5 || value.length > 100){
            setCountryError('Must be more than 2 characters and less than 50 characters');
            return;
        }
        setCountry(event.target.value);
        setCountryError('');
    }
    
    const handlePostalCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        if (value.length < 4 || value.length > 10){
            setPostalCodeError('Must be more than 2 characters and less than 50 characters');
            return;
        }
        setPostalCode(event.target.value);
        setPostalCodeError('');
    }    

    const handleCancel = () => {
        setIsSuccessModalOpen(false);
        setIsFailModalOpen(false);
    };

    const resetFormFields = () => {
        setFirstName('');
        setLastName('');
        setDateOfBirth(null);
        setGender('');
        setEmail('');
        setPhoneNumber('');
        setAddress('');
        setCity('');
        setState('');
        setCountry('');
        setPostalCode('');
    };

    const handleSubmit = async () => {
        const clientData = {
            first_name: firstName,
            last_name: lastName,
            date_of_birth: dateOfBirth,
            gender: gender,
            email: email,
            phone_number: phoneNumber,
            address: address,
            city: city,
            state: state,
            country: country,
            postal_code: postalCode,
            agent_id: agentId,
        }
        if (!agentId) {
            message.error('Failed to create client. Please ensure you are logged in and try again.');
            return;
        }
        try {
            const response = await axios.post(`${BASE_URL}/clients`, clientData, 
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            }
            );

            if(response.status === 201){
                console.log("success")
                setIsSuccessModalOpen(true);
                resetFormFields();
            } else {
                setIsFailModalOpen(true);
            }
        } catch (error) {
            setIsFailModalOpen(true);
            console.log("Error creating client:", error);
        }
    }

    return (
        <Col xs={24}>
            {/* Page header */}
            <PageHeader
                title='Create Client Profile'
                user='John Doe'
            />
            
            {/* Client's Personal Information */}
            <Row justify='center' style={{ margin: '40px 85px' }}>
                <StyledCard title="Client's Personal Information" bordered={false} style={{ width: '100%' }}>
                    <Col xs={24}>
                        <Row gutter={[30, 30]}>
                            {/* First row */}
                            <Col xs={8}>
                                <InputField 
                                    title="First Name" 
                                    placeholder='Enter first name' 
                                    onChange={handleFirstNameChange}
                                    errorMessage={firstNameError}
                                    status={firstNameError ? 'error' : ''}
                                />
                            </Col>
                            <Col xs={8}>
                                <InputField 
                                    title="Last Name" 
                                    placeholder='Enter last name' 
                                    onChange={handleLastNameChange}
                                    errorMessage={lastNameError}
                                    status={lastNameError? 'error' : ''}
                                />
                            </Col>
                            <Col xs={8}>
                                <Typography>
                                    Date of Birth
                                    <Typography.Text style={{ color:'red' }}> *</Typography.Text>
                                </Typography>
                                <DatePicker 
                                    onChange={handleDobChange} 
                                    style={{ width: '100%' }}
                                    format="YYYY-MM-DD"
                                    disabledDate={(current) => {
                                        const today = new Date();
                                        const minDate = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());
                                        const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

                                        return (
                                            current && 
                                            (current.isBefore(minDate) || current.isAfter(maxDate))
                                        );
                                    }}
                                />
                            </Col>

                            {/* Second row */}
                            <Col xs={8}>
                                <Typography>
                                    Gender
                                    <Typography.Text style={{ color:'red' }}> *</Typography.Text>
                                </Typography>
                                <Select
                                    placeholder='Select gender'
                                    style={{ width: '100%' }}
                                    onChange={handleGenderChange}
                                    options={[
                                        { value: 'Male', label: 'Male' },
                                        { value: 'Female', label: 'Female' },
                                        { value: 'Non-binary', label: 'Non-binary' },
                                        { value: 'Prefer not to say', label: 'Prefer not to say' },
                                    ]}
                                />
                            </Col>
                            <Col xs={8}>
                                <InputField 
                                    title="Email" 
                                    placeholder='Enter email' 
                                    onChange={handleEmailChange}
                                    errorMessage={emailError}
                                    status={emailError? 'error' : ''}
                                />
                            </Col>
                            <Col xs={8}>
                                <InputField 
                                    title="Phone Number" 
                                    placeholder='Enter phone number' 
                                    onChange={handlePhoneNumberChange}
                                    errorMessage={phoneNumberError}
                                    status={phoneNumberError? 'error' : ''}
                                />
                            </Col>
                        </Row>
                    </Col>
                </StyledCard>

                {/* More Information */}
                <StyledCard title="More Information" bordered={false} style={{ width: '100%', marginTop: '40px' }}>
                    <Col xs={24}>
                        <Row gutter={[30, 30]}>
                            {/* First row */}
                            <Col xs={16}>
                                <InputField 
                                    title="Address" 
                                    placeholder='Enter address' 
                                    onChange={handleAddressChange}
                                    errorMessage={addressError}
                                    status={addressError? 'error' : ''}
                                />
                            </Col>
                            <Col xs={8}>
                                <InputField 
                                    title="Postal Code" 
                                    placeholder='Enter postal code' 
                                    onChange={handlePostalCodeChange}
                                    errorMessage={postalCodeError}
                                    status={postalCodeError? 'error' : ''}
                                />
                            </Col>

                            {/* Second row */}
                            <Col xs={8}>
                                <InputField 
                                    title="Country" 
                                    placeholder='Enter country' 
                                    onChange={handleCountryChange}
                                    defaultValue={country}
                                    errorMessage={countryError}
                                    status={countryError? 'error' : ''}
                                />
                            </Col>
                            <Col xs={8}>
                                <InputField 
                                    title="City" 
                                    placeholder='Enter city' 
                                    onChange={handleCityChange}
                                    errorMessage={cityError}
                                    status={cityError? 'error' : ''}
                                />
                            </Col>
                            <Col xs={8}>
                                <InputField 
                                    title="State" 
                                    placeholder='Enter state' 
                                    onChange={handleStateChange}
                                    errorMessage={stateError}
                                    status={stateError? 'error' : ''}
                                />
                            </Col>
                        </Row>
                    </Col>
                </StyledCard>

                {/* Buttons */}
                <Col xs={24} style={{ marginTop: '20px' }}>
                    <Row justify='end'>
                        <Button 
                            type='primary' 
                            style={{ marginLeft: '15px', backgroundColor: isFormValid() ? '#dd2839' : undefined}}
                            onClick={handleSubmit}
                            disabled={!isFormValid()}
                        >
                            Submit
                        </Button>
                    </Row>
                </Col>
            </Row>

            {/* Success modal */}
            <Modal 
                open={isSuccessModalOpen} 
                footer={[
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <Button 
                            type="primary" 
                            onClick={handleCancel}
                            style={{ backgroundColor: '#dd2839', width: '30%' }}
                        >
                            Cancel
                        </Button>
                    </div>
                ]}
            >
                <Row justify="center" align="middle" style={{ minHeight: '100%', padding:'30px 30px' }}>
                    <Col style={{ textAlign: 'center' }}>
                        <img 
                            src={successLogo}
                            style={{ height: '40px', width: '40px' }}
                        />
                        <Typography
                            style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '16px' }}
                        >
                            Client has successfully been created
                        </Typography>
                        <Typography
                            style={{ fontSize: '16px', marginTop: '8px' }}
                        >
                            Client profiles can be updated and managed in the 'Manage Profiles' section
                        </Typography>
                    </Col>
                </Row>
            </Modal>
            
            {/* Fail modal */}
            <Modal 
                open={isFailModalOpen} 
                footer={[
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <Button 
                            type="primary" 
                            onClick={handleCancel}
                            style={{ backgroundColor: '#dd2839', width: '30%' }}
                        >
                            Cancel
                        </Button>
                    </div>
                ]}
            >
                <Row justify="center" align="middle" style={{ minHeight: '100%', padding:'30px 30px' }}>
                    <Col style={{ textAlign: 'center' }}>
                        <img 
                            src={failLogo}
                            style={{ height: '40px', width: '40px' }}
                        />
                        <Typography
                            style={{ fontSize: '20px', fontWeight: 'bold', marginTop: '16px' }}
                        >
                            Failed to create client
                        </Typography>
                        <Typography
                            style={{ fontSize: '16px', marginTop: '8px' }}
                        >
                            Client has failed to create. Please try again later.
                        </Typography>
                    </Col>
                </Row>
            </Modal>
        </Col>
    )
}

export default CreateClientProfile;