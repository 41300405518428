import PageHeader from '../PageHeader';
import { Col, message, Row, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios'; 
import DataTable from '../DataTable'; 
import { AccountContext } from "../Admin/Accounts";
import { getSecret } from '../../AwsSecrets';

const columns = [
    {
        title: 'Client ID',
        dataIndex: 'clientId',
        key: 'clientId',
    },
    {
        title: 'Action Time',
        dataIndex: 'actionTime',
        key: 'actionTime',
    },
    {
        title: 'Activity Description',
        dataIndex: 'activityDescription',
        key: 'activityDescription',
    },
    {
        title: 'Action ID',
        dataIndex: 'actionId',
        key: 'actionId',
    },
];

const RecentActivities = () => {
    const [dataSource, setDataSource] = useState([]);
    const accountContext = useContext(AccountContext);
    const [token, setToken] = useState<string | null>(null);
    const [agentId, setAgentId] = useState<string | null>(null);
    const BASE_URL = process.env.REACT_APP_LOG_URL;

    useEffect(() => {
      const getAuthToken = async () => {
        if (!accountContext?.getSession) {

          return;
        }
  
        try {

          const session = await accountContext.getSession();

          //get token
          const authToken = session.token;

          //Get agent Id
          const agentId = await accountContext.getUserId();
          setAgentId(agentId);
          setToken(authToken);
        //   console.log(authToken)
        } catch (error) {
          console.error("Error getting auth token:", error);

          message.error("Failed to get authentication token");
        } finally {

        }
      };
  
      getAuthToken();
    }, [accountContext]);

    // Function to fetch data from the backend using axios
    const fetchData = async () => {
      try {
          // Make sure the token is available before making the request
          if (!token) {
              console.error('No token found');
              return;
          }

          // Pass the token in the Authorization header
          const response = await axios.get(`${BASE_URL}/client/logs/`, {
              headers: {
                  Authorization: `Bearer ${token}`,  // Pass the token here
              },
          });

          const data = response.data;
          console.log("data", data);

          // Transform the backend data into a format suitable for the table
          const transformedData = data.map((item: { action_time: string | number | Date; attribute_name: string; id: any; client_id: any; }) => {
              const actionTime = new Date(item.action_time);
              const date = actionTime.toLocaleDateString();
              const time = actionTime.toLocaleTimeString();

              // Handle multiple attribute updates and prepare a human-readable description
              let activityDescription = 'Update: ';
              if (item.attribute_name) {
                  activityDescription += item.attribute_name.replace('|', ', ');
              }

              return {
                  key: item.id,
                  clientId: item.client_id,
                  actionTime: `${date} ${time}`,
                  activityDescription: activityDescription,
                  actionId: item.id,
              };
          });

          setDataSource(transformedData);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
    };


    // Fetch data on component mount
    useEffect(() => {
        fetchData();
    }, [token]);

    return (
        <Col xs={24}>
            {/* Page header */}
            <PageHeader title='Recent Activities' user='John Doe' />
            
            {/* Data table */}
            <Row justify='center' style={{ marginTop: '60px' }}>
                <Col xs={20}>
                    <DataTable
                        dataSource={dataSource}
                        columns={columns}
                    />
                </Col>
            </Row>
        </Col>
    );
};

export default RecentActivities;
