import { LockOutlined, UserOutlined } from "@ant-design/icons";
import {
  LoginFormPage,
  ProConfigProvider,
  ProFormText,
} from "@ant-design/pro-components";
import { Button, Col, ConfigProvider, message, theme } from "antd";
import { useContext, useEffect, useState } from "react";
import ubsLogo from "../assets/ubs-logo.png";
import background from "../assets/login-background.png";
import { useNavigate } from "react-router-dom";
import { AccountContext } from "../components/Admin/Accounts";

// Define a default context value
const defaultContextValue = {
  authenticate: () => Promise.reject(new Error("Context not available")),
  getSession: () => null,
};
type LoginType = "agent" | "admin";
const LogIn = () => {
  const [loginType, setLoginType] = useState<LoginType>("agent");
  const { token } = theme.useToken();
  const navigate = useNavigate();

  // State variables for email and password
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  // Use context with a default value
  const context = useContext(AccountContext) || defaultContextValue; // Fallback to default context

  const { authenticate } = context; // Now safe to use

  const handleForgotPasswordClick = () => {
    navigate("/forgot-password"); // Adjust the path to match your routing setup
  };

  const handleLogin = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      const data = await authenticate(email, password);
      const role = data["idToken"]["payload"]["custom:role"];
      window.location.href = `${role}-dashboard`;
    } catch (err: any) {
      // Check if the error is due to force password change
      if (
        err.code === "PasswordResetRequiredException" ||
        err.message === "Password reset required for the user"
      ) {

      } else if (
        err.code === "NotAuthorizedException" ||
        err.code === "UserNotFoundException"
      ) {
        // Handle invalid credentials
        message.error("Invalid username or password");
      } else {
        // Handle other types of errors
        message.error("An error occurred during login. Please try again.");
        console.error("Login error:", err);
      }
    }
  };

  return (
    <div style={{ backgroundColor: "white", height: "100vh" }}>
      <LoginFormPage
        backgroundImageUrl={background}
        logo={
          <img
            src={ubsLogo}
            alt="UBS logo"
            style={{ height: "auto", width: "40px" }}
          />
        }
        containerStyle={{
          backgroundColor: "rgba(0, 0, 0,0.65)",
          backdropFilter: "blur(4px)",
          alignItems: "center",
          justifyContent: "center",
        }}
        title="UBS"
        submitter={{
          searchConfig: {
            submitText: "Log In",
          },
          submitButtonProps: {
            style: { backgroundColor: "#E60000", width: "100%" },
            onClick: handleLogin,
          },
        }}
      >
        <Col style={{ marginTop: "20px" }}>
          <ConfigProvider theme={{ token: { colorPrimary: "#E60000" } }}>
            {/* <Tabs
              centered
              activeKey={loginType}
              onChange={(activeKey) => setLoginType(activeKey as LoginType)}
            >
              <Tabs.TabPane key={"agent"} tab={"Agent"} />
              <Tabs.TabPane key={"admin"} tab={"Admin"} />
            </Tabs> */}
          </ConfigProvider>
        </Col>
        {loginType === "agent" && (
          <>
            <ProFormText
              name="email" // Use "email" instead of "username"
              fieldProps={{
                size: "large",
                onChange: (e) => setEmail(e.target.value), // Update email state
                prefix: (
                  <UserOutlined
                    style={{
                      color: token.colorText,
                    }}
                    className={"prefixIcon"}
                  />
                ),
              }}
              placeholder={"Enter email"} // Update placeholder
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: "large",
                onChange: (e) => setPassword(e.target.value), // Update password state
                prefix: (
                  <LockOutlined
                    style={{
                      color: token.colorText,
                    }}
                    className={"prefixIcon"}
                  />
                ),
              }}
              placeholder={"Enter password"}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            />
          </>
        )}
        {loginType === "admin" && (
          <>
            <ProFormText
              name="email" // Use "email" instead of "username"
              fieldProps={{
                size: "large",
                onChange: (e) => setEmail(e.target.value), // Update email state
                prefix: (
                  <UserOutlined
                    style={{
                      color: token.colorText,
                    }}
                    className={"prefixIcon"}
                  />
                ),
              }}
              placeholder={"Enter email"} // Update placeholder
              rules={[
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
            />
            <ProFormText.Password
              name="password"
              fieldProps={{
                size: "large",
                onChange: (e) => setPassword(e.target.value), // Update password state
                prefix: (
                  <LockOutlined
                    style={{
                      color: token.colorText,
                    }}
                    className={"prefixIcon"}
                  />
                ),
              }}
              placeholder={"Enter password"}
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
              ]}
            />
          </>
        )}
        <div style={{ marginBlockEnd: 24 }}>
          <Button
            onClick={handleForgotPasswordClick} // Add onClick handler
            type="link"
            style={{
              float: "right",
              textDecoration: "underline",
              color: "white",
            }}
          >
            Forgot your password?
          </Button>
        </div>
      </LoginFormPage>
    </div>
  );
};

export default () => {
  return (
    <ProConfigProvider dark>
      <LogIn />
    </ProConfigProvider>
  );
};
