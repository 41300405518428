import {
  Button,
  Card,
  Col,
  DatePicker,
  Drawer,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Typography,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { UploadProps } from "antd";
import { message, Upload } from "antd";
import axios from "axios";
import { AccountContext } from "./Admin/Accounts";
import InputField from "./InputField";
import dayjs from "dayjs";
import ClientDetailsDrawer from "./Agent/ClientDetailsDrawer";
const { Dragger } = Upload;

interface DataTableProps {
  dataSource: any[];
  columns: any[];
  enableSearch?: boolean;
  rowKey?: string;
  verifyAction?: boolean;
}

const DataTable = (props: DataTableProps) => {
  const {
    dataSource,
    columns,
    enableSearch = false,
    rowKey,
    verifyAction = false,
  } = props;

  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState<any[]>(props.dataSource);
  const [isClientDrawerOpen, setIsClientDrawerOpen] = useState(false);
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState<any>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // Store selected file here
  const [isEditing, setIsEditing] = useState(false); // Flag for edit mode
  const accountContext = useContext(AccountContext);
  const [token, setToken] = useState<string | null>(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;


  useEffect(() => {
    const getAuthToken = async () => {
      if (!accountContext?.getSession) return;

      try {
        const session = await accountContext.getSession();
        const authToken = session.token;
        setToken(authToken);
        // console.log("token", authToken);
      } catch (error) {
        console.error("Error getting auth token:", error);
        message.error("Failed to get authentication token");
      }
    };

    getAuthToken();
  }, [accountContext]);

  useEffect(() => {
    if (searchText) {
      const lowercasedSearchText = searchText.toLowerCase();
      const filtered = props.dataSource.filter((item) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(lowercasedSearchText)
        )
      );
      setFilteredData(filtered);
    } else {
      setFilteredData(props.dataSource);
    }
  }, [searchText, props.dataSource]);

  const columnsWithLinks = props.columns.map((column) => {
    if (column.title === "Client ID") {
      return {
        ...column,
        render: (text: string) => (
          <Button
            type="link"
            onClick={() => handleClientClick(text)} // Open client details modal on click
            style={{
              color: "#dd2839",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "block",
              maxWidth: "100%",
              textDecoration: "underline",
            }}
          >
            {text}
          </Button>
        ),
      };
    }
    return column;
  });

  const actionColumn = {
    title: "Action",
    key: "action",
    fixed: "right",
    width: 120,
    render: (_: any, record: any) => {
      if (record.is_verified === false || record.is_verified === "false") {
        return (
          <Button
            type="link"
            onClick={() => handleVerifyClick(record)} // Open verification modal on click
            style={{ color: "#dd2839", fontWeight: "bold" }}
          >
            Verify
          </Button>
        );
      }
      return null;
    },
  };

  const handleClientClick = (clientId: string) => {
    const client = props.dataSource.find(
      (client: any) => client.id === clientId
    );
    setSelectedClient(client);
    setIsClientDrawerOpen(true);
  };

  const handleVerifyClick = (record: any) => {
    setSelectedClient(record);
    setIsVerificationModalOpen(true); // Open verification modal
  };

  const handleClientDrawerClose = () => {
    setIsClientDrawerOpen(false);
    setSelectedClient(null);
    setIsEditing(false);
  };

  const handleVerificationModalClose = () => {
    setIsVerificationModalOpen(false);
    setSelectedClient(null);
    setSelectedFile(null); // Clear selected file when modal is closed
  };

  const handleFileChange = (file: File) => {
    setSelectedFile(file); // Store the selected file when it's chosen
  };

  const handleSubmitVerification = async () => {
    if (!selectedFile || !selectedClient || !token) {
      message.error("Please upload a file and select a client.");
      return;
    }

    const clientId = selectedClient.id;
    const apiUrl = `${BASE_URL}/clients/${clientId}/verify`;
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(apiUrl, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      message.success("Verification document uploaded successfully.");
      console.log("Response:", response.data);
      handleVerificationModalClose(); // Close verification modal after successful upload
    } catch (error) {
      message.error("Failed to upload verification document.");
      console.error("Upload error:", error);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveEdit = async () => {
    if (!selectedClient) return;
    try {
      const updatedClient = { ...selectedClient };
      // Save updated client details here
      message.success("Client details updated successfully.");
      setIsEditing(false);
    } catch (error) {
      message.error("Failed to update client details.");
      console.error("Save error:", error);
    }
  };

  const finalColumns = verifyAction
    ? [...columnsWithLinks, actionColumn]
    : columnsWithLinks;

  return (
    <Col>
      {props.enableSearch && (
        <Input
          placeholder="Search..."
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginBottom: "16px" }}
        />
      )}
      <Table
        dataSource={filteredData}
        columns={finalColumns}
        rowKey={props.rowKey}
        scroll={{ x: "max-content" }}
      />

      {/* Client Details Drawer */}
      <ClientDetailsDrawer
        title="Client Details"
        open={isClientDrawerOpen}
        onClose={handleClientDrawerClose}
        selectedClient={selectedClient}
        isEditing={isEditing}
        onSave={handleSaveEdit}
        onEditClick={handleEditClick}
        setSelectedClient={setSelectedClient}
      />

      {/* Verification Modal */}
      <Modal
        title="Verify Client"
        visible={isVerificationModalOpen}
        onCancel={handleVerificationModalClose}
        footer={[
          <Button key="cancel" onClick={handleVerificationModalClose}>
            Cancel
          </Button>,
          <Button
            key="verify"
            type="primary"
            onClick={handleSubmitVerification} // Call file upload on submit
            style={{ backgroundColor: "#dd2839" }}
          >
            Submit Verification
          </Button>,
        ]}
      >
        <Col style={{ padding: "30px 30px" }}>
          <Row style={{ marginBottom: "20px" }}>
            <Typography>
              <b>Name:</b> {selectedClient?.first_name}{" "}
              {selectedClient?.last_name}
            </Typography>
          </Row>
          <Dragger
            name="file"
            multiple={false}
            beforeUpload={handleFileChange} // Store selected file before uploading
            showUploadList={false}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined style={{ color: "grey" }} />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              Upload up to 1 document of evidence of client's identification
            </p>
          </Dragger>
        </Col>
      </Modal>
    </Col>
  );
};

export default DataTable;
