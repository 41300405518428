import React, { useState } from "react";
import {
  TableOutlined,
  FormOutlined,
  BookOutlined,
  BarsOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Col, MenuProps, Typography } from "antd";
import { Layout, Menu, theme } from "antd";
import styled from "styled-components";
import logo from "../assets/ubs-full-logo.png";
import { Content } from "antd/es/layout/layout";
import RecentActivities from "./Agent/RecentActivities";
import CreateClientProfile from "./Agent/CreateClientProfile";
import ManageProfiles from "./Agent/ManageProfiles";
import ViewTransactions from "./Agent/ViewTransactions";
import ChangePassword from "../components/ChangePassword";
import MFASetup from "../components/MFA";
import Settings from "../components/Settings"
const { Sider } = Layout;

const StyledSider = styled(Sider)`
  .ant-layout-sider-trigger {
    background-color: DD2839;
  }
`;

const StyledMenu = styled(Menu)`
  .ant-menu-item-selected {
    background-color: #e60000;
    color: white;
  }
  .ant-menu-item {
    color: white;
  }
`;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Recent Activities", "1", <TableOutlined />),
  getItem("Create Client Profile", "2", <FormOutlined />),
  getItem("Manage Profiles", "3", <BookOutlined />),
  getItem("View Transactions", "4", <BarsOutlined />),
  getItem("Settings", "5", <SettingOutlined />),
];

const AgentMenuBar: React.FC = () => {
  const [selectedKey, setSelectedKey] = useState<string>("1"); // State for selected key
  const [selectedComponent, setSelectedComponent] = useState<string>("");
  const handleSelectLogo = () => {
    setSelectedKey("1");
  };

  const onMenuSelect = (e: { key: string }) => {
    setSelectedKey(e.key);
    if (e.key === "5") {
      setSelectedComponent("");
    }
  };

  const renderSettingsContent = () => {
    switch (selectedComponent) {
      case "ChangePassword":
        return <ChangePassword />;
      case "MFASetup":
        return <MFASetup />;
      default:
        return null;
    }
  };

  const renderContent = () => {
    switch (selectedKey) {
      case "1":
        return <RecentActivities />;
      case "2":
        return <CreateClientProfile />;
      case "3":
        return <ManageProfiles />;
      case "4":
        return <ViewTransactions />;
      case "5":
        return selectedComponent ? (
          renderSettingsContent()
        ) : (
          <Settings onSelectComponent={setSelectedComponent} />
        );
      default:
        return <RecentActivities />;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <StyledSider style={{ background: "black", padding: "0px" }}>
        <Col>
          <img
            src={logo}
            style={{
              height: "33px",
              width: "auto",
              margin: "45px 63px 45px 30px",
            }}
            onClick={handleSelectLogo}
          />
          <Typography
            style={{
              color: "white",
              marginLeft: "30px",
              marginBottom: "5px",
              fontWeight: "bold",
            }}
          >
            Agent Portal
          </Typography>
          <StyledMenu
            theme="dark"
            selectedKeys={[selectedKey]}
            onSelect={onMenuSelect}
            mode="inline"
            items={items}
            style={{ background: "black" }}
          />
        </Col>
      </StyledSider>
      <Layout>
        <Content>{renderContent()}</Content>
      </Layout>
    </Layout>
  );
};

export default AgentMenuBar;
