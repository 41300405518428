import { message } from "antd";

import {
  User,
  Client,
  CreateUserRequest,
  AdminLog,
  UpdateUserParams,
  ToggleStatusParams,
  ToggleStatusRequestBody,
} from "../userTypes";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const LOG_URL = process.env.REACT_APP_LOG_URL;
//Create new user
const createUser = async (userData: CreateUserRequest, token: string) => {
  const url = `${BASE_URL}/create-user`;
  const requestBody = JSON.stringify(userData); // Convert to JSON string

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: requestBody,
    });

    // First check if the response is ok
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || "Failed to create user");
    }

    // If response is ok, parse the response
    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

//Fetch user list from cognito
const fetchUsers = async (token: string) => {
  const users: User[] = [];
  try {
    const response = await fetch(`${BASE_URL}/users`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log(token);
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `Network response was not ok: ${response.status} ${errorMessage}`
      );
    }
    const data = await response.json(); // Parse the response as JSON
    const usersData = Array.isArray(data) ? data : JSON.parse(data.body);
    return usersData;
  } catch (error: any) {
    console.error("Error fetching users:", error.message);
    message.error(`Failed to fetch users: ${error.message}`);
    return users; // Return an empty array in case of error
  }
};

//Update User
const updateUserDetails = async (
  token: string,
  userData: UpdateUserParams
): Promise<Response> => {
  const response = await fetch(`${BASE_URL}/users`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userData),
  });

  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(
      `Failed to update user: ${response.status} ${errorMessage}`
    );
  }

  return response;
};

//Fetch user's client
const fetchAllClient = async (token: string) => {
  const clients: Client[] = [];
  try {
    const response = await fetch(`${BASE_URL}/clients`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `Network response was not ok: ${response.status} ${errorMessage}`
      );
    }
    const data = await response.json(); // Parse the response as JSON
    const clientData = Array.isArray(data) ? data : JSON.parse(data.body);
    return clientData;
  } catch (error: any) {

    message.error(`No users to fetch`);
    return clients; // Return an empty array in case of error
  }
};

const toggleStatus = async ({
  username,
  action,
  token,
  adminId,
}: ToggleStatusParams): Promise<Response> => {
  console.log("Function called"); // Add this to verify function entry
  
  const requestBody: ToggleStatusRequestBody = {
    username,
    action,
    adminId,
  };
  console.log("Request body:", requestBody); // Log the actual data

  try {
    console.log("Attempting fetch to:", `${BASE_URL}/users`);
    const response = await fetch(`${BASE_URL}/users`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestBody),
    });
    console.log("Response received:", response.status);

    if (!response.ok) {
      const errorMessage = await response.text();
      console.error("Error occurred:", errorMessage);
      throw new Error(
        `Failed to ${action} user: ${response.status} ${errorMessage}`
      );
    }

    return response;
  } catch (error) {
    console.error("Error in toggleStatus:", error);
    throw error;
  }
};

const sendCode = async (email: string) => {
  try {
    const response = await fetch(`${BASE_URL}/send-code`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email.toLowerCase() }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || "Failed to send verification code");
    }

    await response.json();

    message.success("Verification code sent to your email");
  } catch (error: any) {
    message.error(error.message);
  } finally {
  }
};

const passwordReset = async (email: string, code: string, password: string) => {
  try {
    const response = await fetch(`${BASE_URL}/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        code: code,
        password: password,
      }),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || "Failed to reset password");
    }
    await response.json();
    message.success("Password reset successfully");
    return true;
    // You can add navigation logic here
  } catch (error: any) {
    message.error(error.message);
    return false;
  }
};

//Fetch user list from cognito
const fetchAdminLogs = async (token:string) => {
  const logs: AdminLog[] = [];
  try {
    const response = await fetch(`${LOG_URL}/users/logs/`, {
      method: "GET",
      headers: {
             Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    if (!response.ok) {
      const errorMessage = await response.text();
      throw new Error(
        `Network response was not ok: ${response.status} ${errorMessage}`
      );
    }

    const data = await response.json();
    const adminLogs = Array.isArray(data) ? data : JSON.parse(data.body);
    return adminLogs;
  } catch (error: any) {
    console.error("Error fetching log:", error.message);
    message.error(`Failed to fetch log: ${error.message}`);
    return logs;
  }
};

export {
  createUser,
  fetchUsers,
  toggleStatus,
  sendCode,
  passwordReset,
  fetchAdminLogs,
  fetchAllClient,
  updateUserDetails,
};
