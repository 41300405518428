import React, { createContext, ReactNode, useCallback, useState } from "react";
import AWS from "aws-sdk";
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import Pool from "../../states/UserPool";
import { TOTPVerificationModal } from "../TOTPVerificationModal";
import { message } from "antd";
// Initialize the Cognito service provider
const cognito = new AWS.CognitoIdentityServiceProvider({
  region: process.env.REACT_APP_AWS_REGION,
});

// Define the context type
interface AccountContextType {
  authenticate: (Username: string, Password: string) => Promise<any>;
  getSession: () => Promise<any>;
  getUserId: () => Promise<string | null>;
  refreshSession: () => Promise<any>;
  logout: () => void;
}

// Create a context with the defined type
const AccountContext = createContext<AccountContextType | undefined>(undefined);

interface AccountProps {
  children: ReactNode;
}

const Account: React.FC<AccountProps> = (props) => {
  const [showTOTPModal, setShowTOTPModal] = useState(false);
  const [pendingUser, setPendingUser] = useState<CognitoUser | null>(null);
  const authenticate = async (
    Username: string,
    Password: string
  ): Promise<any> => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess:", data);
          resolve(data);
        },
        onFailure: (err) => {
          // Check if the error is a UserNotFoundException
          if (err.code === "UserNotFoundException") {
            console.error("User does not exist:", err.message);
            alert("User does not exist. Please check the username or sign up.");
          } else {
            console.error("onFailure:", err);
          }
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log("newPasswordRequired:", data);
          window.location.href = `/force-change-password?username=${encodeURIComponent(user.getUsername())}`;
          resolve(data);
        },
        totpRequired: () => {
          setPendingUser(user);
          setShowTOTPModal(true);
        },
        mfaSetup: () => {
          // Create a session when MFA setup is triggered
          const sessionInfo = {
            user,
            username: user.getUsername(),
            mfaRequired: true,
          };
          localStorage.setItem("mfaSession", JSON.stringify(sessionInfo));
          window.location.href = `/mfa-setup`;
        },
      });
    });
  };

  const getUserId = async (): Promise<string | null> => {
    const user = Pool.getCurrentUser(); // Get the current user from the user pool
    if (!user) {
      return null; // No user logged in
    }

    return user.getUsername(); // Return the username directly
  };

  const handleTOTPVerification = (totpCode: string) => {
    if (!pendingUser) return;

    pendingUser.sendMFACode(
      totpCode,
      {
        onSuccess: async () => {
          setShowTOTPModal(false);
          setPendingUser(null);

          // Fetch user attributes after MFA
          const attributes = await new Promise<{ [key: string]: string }>(
            (resolve, reject) => {
              pendingUser.getUserAttributes((err, attributes) => {
                if (err) {
                  reject(err);
                } else {
                  const results: { [key: string]: string } = {};
                  if (attributes) {
                    for (let attribute of attributes) {
                      const { Name, Value } = attribute;
                      results[Name] = Value;
                    }
                  }
                  resolve(results);
                }
              });
            }
          );

          const role = attributes["custom:role"];
          window.location.href = `/${role}-dashboard`;
        },
        onFailure: (err) => {
          message.error("Incorrect code. Please try again.");
        },
      },
      "SOFTWARE_TOKEN_MFA"
    );
  };

  const refreshSession = useCallback(async (): Promise<any> => {
    return new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();

      if (!user) {
        reject(new Error("No user found"));
        return;
      }

      user.getSession((err: any, session: any) => {
        if (err) {
          reject(err);
          return;
        }

        // Get the refresh token from the current session
        const refreshToken = session.getRefreshToken();

        if (!refreshToken) {
          reject(new Error("No refresh token available"));
          return;
        }

        // Refresh the session using the refresh token
        user.refreshSession(refreshToken, async (err: any, session: any) => {
          if (err) {
            console.error("Failed to refresh session:", err);
            reject(err);
            return;
          }

          try {
            // Get user attributes after refresh
            const attributes = await new Promise<{ [key: string]: string }>(
              (resolve, reject) => {
                user.getUserAttributes((err, attributes) => {
                  if (err) {
                    reject(err);
                  } else {
                    const results: { [key: string]: string } = {};
                    if (attributes) {
                      for (let attribute of attributes) {
                        const { Name, Value } = attribute;
                        results[Name] = Value;
                      }
                    }
                    resolve(results);
                  }
                });
              }
            );

            const accessToken = session.accessToken.jwtToken;
            const token = session.getIdToken().getJwtToken();

            // Check MFA status
            const mfaEnabled = await new Promise<boolean>((resolve) => {
              cognito.getUser(
                {
                  AccessToken: accessToken,
                },
                (err: any, data: any) => {
                  if (err) resolve(false);
                  else
                    resolve(
                      data.UserMFASettingList &&
                        data.UserMFASettingList.includes("SOFTWARE_TOKEN_MFA")
                    );
                }
              );
            });

            resolve({
              user,
              accessToken,
              mfaEnabled,
              token,
              headers: {
                "x-api-key": attributes["custom:apikey"],
                Authorization: token,
              },
              ...session,
              ...attributes,
            });
          } catch (error) {
            reject(error);
          }
        });
      });
    });
  }, []);

  const getSession = async (): Promise<any> => {
    try {
      const session = await new Promise((resolve, reject) => {
        const user = Pool.getCurrentUser();
        if (!user) {
          reject(new Error("No user is currently logged in."));
          return;
        }

        user.getSession(async (err: any, session: any) => {
          if (err) {
            // If session error, try to refresh
            try {
              const refreshedSession = await refreshSession();
              resolve(refreshedSession);
            } catch (refreshError) {
              reject(refreshError);
            }
          } else {
            // Process normal session as before
            const attributes = await new Promise<{ [key: string]: string }>(
              (resolve, reject) => {
                user.getUserAttributes((err, attributes) => {
                  if (err) {
                    reject(err);
                  } else {
                    const results: { [key: string]: string } = {};
                    if (attributes) {
                      for (let attribute of attributes) {
                        const { Name, Value } = attribute;
                        results[Name] = Value;
                      }
                      resolve(results);
                    }
                  }
                });
              }
            );

            const accessToken = session.accessToken.jwtToken;
            const mfaEnabled = await new Promise<boolean>((resolve) => {
              cognito.getUser(
                {
                  AccessToken: accessToken,
                },
                (err: any, data: any) => {
                  if (err) resolve(false);
                  else
                    resolve(
                      data.UserMFASettingList &&
                        data.UserMFASettingList.includes("SOFTWARE_TOKEN_MFA")
                    );
                }
              );
            });

            const token = session.getIdToken().getJwtToken();

            resolve({
              user,
              accessToken,
              mfaEnabled,
              token,
              headers: {
                "x-api-key": attributes["custom:apikey"],
                Authorization: token,
              },
              ...session,
              ...attributes,
            });
          }
        });
      });

      return session;
    } catch (error) {
      console.error("Session error:", error);
      throw error;
    }
  };

  const logout = () => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.signOut();
      // Clear any stored session data
      localStorage.removeItem("mfaSession");
      // You might want to add additional cleanup here
    }
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        refreshSession,
        logout,
        getUserId,
      }}
    >
      <TOTPVerificationModal
        isVisible={showTOTPModal}
        onVerify={handleTOTPVerification}
        onCancel={() => {
          setShowTOTPModal(false);
          setPendingUser(null);
        }}
      />
      {props.children}
    </AccountContext.Provider>
  );
};

export { Account, AccountContext };
