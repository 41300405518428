import React, { useContext, useState, useEffect } from "react";
import AdminMenuBar from "../components/Admin/AdminMenuBar";
import MFASetup from "../components/MFA";
import RegisterUser from "../components/registerUsers/RegisterUser";
import ListUser from "../components/Admin/UserList";
import ForceChangePassword from "../components/forcePasswordChange/ForcePasswordChange";
import ChangePassword from "../components/ChangePassword";
import Settings from "../components/Settings";
import { AccountContext } from "../components/Admin/Accounts";
import PageHeader from "../components/PageHeader";
import ViewUserActivities from "../components/Admin/ViewUserActivities";

const AdminDashboard: React.FC = () => {
  const [selectedComponent, setSelectedComponent] =
    useState<string>("ActivityPage"); // State to track the component
  const [pageTitle, setPageTitle] = useState<string>("");
  const accountContext = useContext(AccountContext);

  if (!accountContext) {
    throw new Error("Account context is not available.");
  }

  useEffect(() => {
    // Update page title based on selected component
    switch (selectedComponent) {
      case "RecentActivities":
        setPageTitle("Recent Activities");
        break;
      case "ActivityPage":
        setPageTitle("User Activities");
        break;
      case "MFASetup":
        setPageTitle("MFA Setup");
        break;
      case "RegisterUser":
        setPageTitle("Create User Profile");
        break;
      case "ListUser":
        setPageTitle("Manage Accounts");
        break;
      case "ForceChangePassword":
        setPageTitle("Set up new password");
        break;
      case "ChangePassword":
        setPageTitle("Change Password");
        break;
      case "Settings":
        setPageTitle("Settings");
        break;
      default:
        setPageTitle("Recent Activities");
    }
  }, [selectedComponent]); // Only run when selectedComponent changes

  const renderComponent = () => {
    switch (selectedComponent) {
      case "RecentActivities":
        return <ViewUserActivities showPagination={false} />;
      case "ActivityPage":
        return <ViewUserActivities showPagination={true} />;
      case "MFASetup":
        return <MFASetup />;
      case "RegisterUser":
        return <RegisterUser />;
      case "ListUser":
        return <ListUser />;
      case "ForceChangePassword":
        return <ForceChangePassword />;
      case "ChangePassword":
        return <ChangePassword />;
      /* case "UpdateDetails":
         return <UpdateDetails />; */
      case "Settings":
        return <Settings onSelectComponent={setSelectedComponent} />;
      default:
        return <p>Please select an action from the buttons above.</p>;
    }
  };

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      <AdminMenuBar onSelectComponent={setSelectedComponent} />

      <div style={{ flex: 1, backgroundColor: "#f5f5f5" }}>
        <PageHeader title={pageTitle} user="John Doe" />
        {renderComponent()}
      </div>
    </div>
  );
};

export default AdminDashboard;
