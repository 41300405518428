import { useState } from "react";
import {
  Form,
  Input,
  Button,
  message,
  Card,
  Typography,
  Space,
  Divider,
  List,
} from "antd";
import { LockOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import userPool from "../../states/UserPool";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { useNavigate, useLocation } from "react-router-dom";

const { Title, Text } = Typography;

const ForcePasswordChange = () => {
  const [temporaryPassword, setTemporaryPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLengthValid, setIsLengthValid] = useState<boolean>(true);
  const [isUppercaseValid, setIsUppercaseValid] = useState<boolean>(true);
  const [isLowercaseValid, setIsLowercaseValid] = useState<boolean>(true);
  const [isNumberValid, setIsNumberValid] = useState<boolean>(true);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState<boolean>(true);
  const [isMatchValid, setIsMatchValid] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const username = queryParams.get("username") as string;
  const navigate = useNavigate();

  const validatePassword = (password: string) => {
    setIsLengthValid(password.length >= 8);
    setIsUppercaseValid(/[A-Z]/.test(password));
    setIsLowercaseValid(/[a-z]/.test(password));
    setIsNumberValid(/\d/.test(password));
    setIsSpecialCharValid(/[!@#$%^&*(),.?":{}|<>]/.test(password));
  };

  const handleSubmit = async () => {
    validatePassword(newPassword);
    setIsMatchValid(newPassword === confirmPassword);

    if (
      !isLengthValid ||
      !isUppercaseValid ||
      !isLowercaseValid ||
      !isNumberValid ||
      !isSpecialCharValid ||
      !isMatchValid
    ) {
      message.error("New password does not meet the required criteria.");
      return;
    }

    setLoading(true);
    const user = new CognitoUser({ Username: username, Pool: userPool });
    const authDetails = new AuthenticationDetails({
      Username: username,
      Password: temporaryPassword,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (session: CognitoUserSession) => {
        message.success("Authenticated successfully!");
        setLoading(false);
      },
      onFailure: (err) => {
        message.error(err.message || JSON.stringify(err));
        setLoading(false);
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        // Access custom:role here
        const userRole = userAttributes["custom:role"];
        console.log("User role:", userRole); // Use or store this as needed

        user.completeNewPasswordChallenge(
          newPassword,
          {},
          {
            onSuccess: (session: CognitoUserSession) => {
              message.success("Password changed successfully!");
              setLoading(false);
              // Navigate based on role if needed

              navigate(`/${userRole}-dashboard`);
            },
            onFailure: (err) => {
              message.error(err.message || JSON.stringify(err));
              setLoading(false);
            },
          }
        );
      },
      mfaSetup: () => {
        window.location.href = `/mfa-setup`;
      },
    });
  };

  const passwordRequirements = [
    { text: "Minimum length of 8 characters", valid: isLengthValid },
    { text: "At least 1 uppercase letter", valid: isUppercaseValid },
    { text: "At least 1 lowercase letter", valid: isLowercaseValid },
    { text: "At least 1 number", valid: isNumberValid },
    {
      text: "At least 1 special character (e.g., !@#$%^&*)",
      valid: isSpecialCharValid,
    },
    { text: "Passwords match", valid: isMatchValid },
  ];

  return (
    <div
      style={{
        maxWidth: "500px",
        margin: "40px auto",
        padding: "0 20px",
      }}
    >
      <Card>
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Title level={2} style={{ margin: 0, textAlign: "center" }}>
            Change Password
          </Title>

          <Text
            type="secondary"
            style={{ textAlign: "center", display: "block" }}
          >
            Please change your temporary password to continue
          </Text>

          <Form
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
          >
            <Form.Item
              name="temporaryPassword"
              label="Temporary Password"
              rules={[
                {
                  required: true,
                  message: "Please enter your temporary password!",
                },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "#bfbfbf" }} />}
                placeholder="Enter temporary password"
                value={temporaryPassword}
                onChange={(e) => setTemporaryPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="newPassword"
              label="New Password"
              rules={[
                { required: true, message: "Please enter your new password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "#bfbfbf" }} />}
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  validatePassword(e.target.value);
                }}
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label="Confirm New Password"
              rules={[
                {
                  required: true,
                  message: "Please confirm your new password!",
                },
              ]}
              validateStatus={!isMatchValid && confirmPassword ? "error" : ""}
              help={
                !isMatchValid && confirmPassword ? "Passwords do not match" : ""
              }
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: "#bfbfbf" }} />}
                placeholder="Confirm new password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setIsMatchValid(e.target.value === newPassword);
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={loading}
                size="large"
              >
                Change Password
              </Button>
            </Form.Item>
          </Form>

          <Divider plain>Password Requirements</Divider>

          <List
            size="small"
            dataSource={passwordRequirements}
            renderItem={(item) => (
              <List.Item style={{ padding: "8px 0", border: "none" }}>
                <Space>
                  {item.valid ? (
                    <CheckOutlined style={{ color: "#52c41a" }} />
                  ) : (
                    <CloseOutlined style={{ color: "#ff4d4f" }} />
                  )}
                  <Text type={item.valid ? "success" : "danger"}>
                    {item.text}
                  </Text>
                </Space>
              </List.Item>
            )}
          />
        </Space>
      </Card>
    </div>
  );
};

export default ForcePasswordChange;
