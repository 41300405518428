import PageHeader from '../PageHeader';
import { Col, message, Row, Space, Table, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from '../DataTable';
import { ColumnsType } from 'antd/es/table';
import axios from 'axios';
import { AccountContext } from "../Admin/Accounts";
import { getSecret } from '../../AwsSecrets';

  interface ClientProfile {
    id: string;
    first_name: string;
    last_name: string;
    date_of_birth: string;
    gender: string;
    email: string;
    phone_number: string;
    address: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
    is_verified: boolean;
  }

  const capitalizeTitle = (str: string) =>
    str
        .toLowerCase()
        .replace(/_/g, ' ')
        .replace(/\b\w/g, (char) => char.toUpperCase());

const ManageProfiles = () => {
  const [dataSource, setDataSource] = useState<ClientProfile[]>([]);
  const [columns, setColumns] = useState<ColumnsType<ClientProfile>>([]);
  const accountContext = useContext(AccountContext);
  const [token, setToken] = useState<string | null>(null);
  const [agentId, setAgentId] = useState<string | null>(null);
  const BASE_URL = process.env.REACT_APP_BASE_URL;
    useEffect(() => {
        const getAuthToken = async () => {
          if (!accountContext?.getSession) {

            return;
          }
    
          try {

            const session = await accountContext.getSession();

            //get token
            const authToken = session.token;

            //Get agent Id
            const agentId = await accountContext.getUserId();
            setAgentId(agentId);
            setToken(authToken);
            console.log("token", authToken)
            console.log("agentId", agentId)
          } catch (error) {
            console.error("Error getting auth token:", error);

            message.error("Failed to get authentication token");
          } finally {
 
          }
        };
    
        getAuthToken();
      }, [accountContext]);

  useEffect(() => {
    // Fetch data from backend
    const fetchData = async () => {
      if (!token) {
        return;
      }
      try {
        const response = await axios.get<ClientProfile[]>(`${BASE_URL}/clients/agent/`,
        { headers: {
          'Authorization': `Bearer ${token}`,
          'agent-id': agentId,
        },});
        const data = response.data;

        // Set data source
        setDataSource(data);

        // Generate columns dynamically based on the keys of the first object
        if (data.length > 0) {
          const dynamicColumns = [
            {
              title: 'Client ID',
              dataIndex: 'id',
              key: 'id',
            },
            ...Object.keys(data[0])
              .filter((key) => key !== 'id') // Exclude 'id' to avoid duplication
              .map((key) => {
                // Custom render for is_verified
                if (key === 'is_verified') {
                  return {
                    title: capitalizeTitle(key),
                    dataIndex: key as keyof ClientProfile,
                    key: key,
                    render: (value: boolean | string) => {
                      if (value === true || value === 'true') {
                        return <Tag color="green">Verified</Tag>;
                      } else {
                        return <Tag color="red">Not Verified</Tag>;
                      }
                    },
                  };
                }
          
                // Default render
                return {
                  title: capitalizeTitle(key),
                  dataIndex: key as keyof ClientProfile,
                  key: key,
                };
              }),
          ];          
          setColumns(dynamicColumns);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [token]);

    return (
        <Col xs={24}>
            {/* Page header */}
            <PageHeader
                title='Manage Client Profiles'
                user='John Doe'
            />
            
            {/* Data table */}
            <Row justify='center' style={{ marginTop:'60px'}}>
                <Col xs={20}> 
                    <DataTable
                        dataSource={dataSource}
                        columns={columns}
                        rowKey="id"
                        verifyAction={true}
                    />
                </Col>
            </Row>
        </Col>
    )
}

export default ManageProfiles;