import React, { useState, useContext } from "react";
import { AccountContext } from "./Admin/Accounts";
import { Form, Input, Button, Typography, message } from "antd";

const { Title } = Typography;

const ChangePassword: React.FC = () => {
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  const [isLengthValid, setIsLengthValid] = useState<boolean>(true);
  const [isUppercaseValid, setIsUppercaseValid] = useState<boolean>(true);
  const [isLowercaseValid, setIsLowercaseValid] = useState<boolean>(true);
  const [isNumberValid, setIsNumberValid] = useState<boolean>(true);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState<boolean>(true);
  const [isMatchValid, setIsMatchValid] = useState<boolean>(true); // New state for password match

  const accountContext = useContext(AccountContext);

  if (!accountContext) {
    throw new Error("Account context is not available."); // Optional: handle this gracefully
  }

  const { getSession } = accountContext;

  const validatePassword = (password: string) => {
    setIsLengthValid(password.length >= 8);
    setIsUppercaseValid(/[A-Z]/.test(password));
    setIsLowercaseValid(/[a-z]/.test(password));
    setIsNumberValid(/\d/.test(password));
    setIsSpecialCharValid(/[!@#$%^&*(),.?":{}|<>]/.test(password));
  };

  const onSubmit = async (values: { password: string; newPassword: string; confirmPassword: string }) => {
    validatePassword(values.newPassword);

    // Check if all validations passed
    if (!isLengthValid || !isUppercaseValid || !isLowercaseValid || !isNumberValid || !isSpecialCharValid || !isMatchValid) {
      message.error('New password does not meet the required criteria.');
      return;
    }

    try {
      const { user } = await getSession();

      user.changePassword(values.password, values.newPassword, (err: any, result: any) => {
        if (err) {
          console.error(err);
          message.error('Error changing password.'); // Show error message
        } else {
          console.log(result);
          message.success('Password changed successfully!'); // Show success message
        }
      });
    } catch (error) {
      console.error("Error during password change:", error);
      message.error('An error occurred during the password change.'); // Show error message
    }
  };

  return (
    <div style={{ margin: '40px 85px', padding: '20px', backgroundColor:"white" ,borderRadius:"10px"}}>
      <Title level={2}>Change Password</Title>
      <Form
        name="changePassword"
        onFinish={onSubmit}
        layout="vertical"
        requiredMark={false}
      >
        <Form.Item
          name="password"
          label="Current Password"
          rules={[{ required: true, message: 'Please input your current password!' }]}
        >
          <Input.Password
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            placeholder="Enter your current password"
          />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[{ required: true, message: 'Please input your new password!' }]}
        >
          <Input.Password
            value={newPassword}
            onChange={(event) => {
              setNewPassword(event.target.value);
              validatePassword(event.target.value); // Validate as user types
            }}
            placeholder="Enter your new password"
          />
        </Form.Item>

        <Form.Item
          name="confirmPassword"
          label="Confirm New Password"
          rules={[{ required: true, message: 'Please confirm your new password!' }]}
        >
          <Input.Password
            value={confirmPassword}
            onChange={(event) => {
              setConfirmPassword(event.target.value);
              setIsMatchValid(event.target.value === newPassword); // Check if passwords match
            }}
            placeholder="Confirm your new password"
            style={{ borderColor: isMatchValid ? '' : 'red' }} // Change border color if passwords don't match
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Change Password
          </Button>
        </Form.Item>
      </Form>

      {/* Password requirements */}
      <div style={{ marginTop: '20px' }}>
        <Title level={4}>Password Requirements:</Title>
        <ul>
          <li style={{ color: isLengthValid ? 'green' : 'red' }}>
            Minimum length of 8 characters
          </li>
          <li style={{ color: isUppercaseValid ? 'green' : 'red' }}>
            At least 1 uppercase letter
          </li>
          <li style={{ color: isLowercaseValid ? 'green' : 'red' }}>
            At least 1 lowercase letter
          </li>
          <li style={{ color: isNumberValid ? 'green' : 'red' }}>
            At least 1 number
          </li>
          <li style={{ color: isSpecialCharValid ? 'green' : 'red' }}>
            At least 1 special character (e.g., !@#$%^&*)
          </li>
          <li style={{ color: isMatchValid ? 'green' : 'red' }}>
            Passwords match
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ChangePassword;
