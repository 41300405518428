import React, { useEffect, useState, useContext } from "react";
import { AccountContext } from "./Accounts";
import { message, Table, Button, Modal, Form, Input } from "antd";
import {
  fetchUsers,
  toggleStatus,
  fetchAllClient,
  updateUserDetails,
} from "../../api/user";
import { User, Client, Attribute, ToggleStatusParams } from "../../userTypes";
import Loading from "../Loading";


const UserList: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const accountContext = useContext(AccountContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [isClientModalVisible, setIsClientModalVisible] =
    useState<boolean>(false);
  const [selectedUserClients, setSelectedUserClients] = useState<Client[]>([]);
  const [form] = Form.useForm();
  const ROOT_USER_ID = process.env.REACT_APP_ROOT_ADMIN;


  if (!accountContext) {
    throw new Error("Account context is not available.");
  }

  const { getSession, getUserId } = accountContext;


  const filterUsersByRole = (users: User[], Id: String) => {
    if (Id !== ROOT_USER_ID) {
      // If non-root user, only show users with role 'agent'
      return users.filter(
        (user) =>
          getAttributeValue(user.Attributes, "custom:role").toLowerCase() ===
          "agent"
      );
    }
    return users; // For root users, return all users
  };

  useEffect(() => {
    const fetchAndSetUsers = async () => {
      const ID = await getUserId(); // Call the function to get the user ID
      const session = await getSession();
      setUserId(ID);
      if (!session) {
        throw new Error("Session is undefined");
      }

      let { token } = session;
      if (typeof token !== "string") {
        token = token.jwtToken;
      }

      setLoading(true);
      const fetchedUsers = await fetchUsers(token);

      const filteredByRole = filterUsersByRole(fetchedUsers, ID as string);
      setUsers(filteredByRole);
      setFilteredUsers(filteredByRole);

      //fetch agent clients
      const clientsList = await fetchAllClient(token);

      setClients(clientsList);

      setLoading(false);
    };

    fetchAndSetUsers();
  }, [getSession]);

  useEffect(() => {
    const filtered = users.filter((user) => {
      const firstName = getAttributeValue(
        user.Attributes,
        "given_name"
      ).toLowerCase();
      return firstName.includes(searchText.toLowerCase());
    });
    setFilteredUsers(filtered);
  }, [searchText, users]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const getAttributeValue = (attributes: Attribute[], name: string) => {
    const attribute = attributes.find((attr) => attr.Name === name);
    return attribute ? attribute.Value : "";
  };

  // Function to toggle between enable and disable
  const toggleUserStatus = async (
    username: string,
    isEnabled: boolean,
    name: string
  ) => {
    try {
      const session = await getSession();
      let { token } = session;

      if (typeof token !== "string") {
        token = token.jwtToken;
      }

      const action = isEnabled ? "disable" : "enable";

      const toggleParams: ToggleStatusParams = {
        username,
        action,
        token,
        adminId: userId as string,
      };
      const response = await toggleStatus(toggleParams);

      if (!response.ok) {
        const errorMessage = await response.text();
        throw new Error(
          `Failed to ${action} user: ${response.status} ${errorMessage}`
        );
      }

      message.success(
        `${name} ${isEnabled ? "disabled" : "enabled"} successfully.`
      );

      // Update the user status locally after successful API call
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.Username === username ? { ...user, Enabled: !isEnabled } : user
        )
      );
    } catch (error: any) {
      console.error(
        `Error ${isEnabled ? "disabling" : "enabling"} user:`,
        error.message
      );
      message.error(
        `Failed to ${isEnabled ? "disable" : "enable"} user: ${error.message}`
      );
    }
  };

  const updateUser = async (values: {
    firstName: string;
    lastName: string;
    role: string;
  }) => {
    if (!currentUser) return;

    try {
      const session = await getSession();
      let { token } = session;

      if (typeof token !== "string") {
        token = token.jwtToken;
      }

      const requestBody = {
        userName: currentUser.Username,
        firstName: values.firstName,
        lastName: values.lastName,
        role: values.role,
        adminId: userId as string,
      };

      const response = updateUserDetails(token, requestBody);

      if (!response) {
        console.log("Error creating user");
        throw new Error(`Failed to update user`);
      }

      message.success(`User ${requestBody.firstName} updated successfully.`);
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.Username === currentUser.Username
            ? { ...user, Attributes: updateAttributes(user.Attributes, values) }
            : user
        )
      );
      setIsModalVisible(false);
      form.resetFields();
    } catch (error: any) {
      console.error("Error updating user:", error.message);
      message.error(`Failed to update user: ${error.message}`);
    }
  };

  // Add a function to get user ID from attributes
  const getUserIdFromAttributes = (attributes: Attribute[]) => {
    const userIdAttr = attributes.find((attr) => attr.Name === "sub");
    return userIdAttr ? userIdAttr.Value : "";
  };

  // Add a function to get clients for a specific user
  const getUserClients = (user: User) => {
    const userId = getUserIdFromAttributes(user.Attributes);
    const userClients = clients.filter((client) => client.agent_id === userId);
    return userClients;
  };

  const updateAttributes = (
    attributes: Attribute[],
    values: { firstName: string; lastName: string; role: string }
  ) => {
    return attributes.map((attr) => {
      switch (attr.Name) {
        case "given_name":
          return { ...attr, Value: values.firstName };
        case "family_name":
          return { ...attr, Value: values.lastName };
        case "custom:role":
          return { ...attr, Value: values.role };
        default:
          return attr;
      }
    });
  };

  const handleUpdateUser = (user: User) => {
    setCurrentUser(user);
    form.setFieldsValue({
      firstName: getAttributeValue(user.Attributes, "given_name"),
      lastName: getAttributeValue(user.Attributes, "family_name"),
      role: getAttributeValue(user.Attributes, "custom:role"),
    });
    setIsModalVisible(true);
  };

  const showClientModal = (user: User) => {
    const userClients = getUserClients(user);
    setSelectedUserClients(userClients);
    setIsClientModalVisible(true);
  };

  const columns = [
    {
      title: "First Name",
      key: "firstName",
      render: (user: User) => getAttributeValue(user.Attributes, "given_name"),
    },
    {
      title: "Last Name",
      key: "lastName",
      render: (user: User) => getAttributeValue(user.Attributes, "family_name"),
    },
    {
      title: "Role",
      key: "role",
      render: (user: User) => getAttributeValue(user.Attributes, "custom:role"),
    },
    {
      title: "Clients",
      key: "clients",
      render: (user: User) => {
        const userClients = getUserClients(user);
        return (
          <Button onClick={() => showClientModal(user)} type="default">
            Total Clients: {userClients.length}
          </Button>
        );
      },
    },

    {
      title: "Action",
      key: "action",
      align: "right" as "right",
      render: (user: User) => (
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            type="default"
            onClick={() =>
              toggleUserStatus(
                user.Username,
                user.Enabled,
                getAttributeValue(user.Attributes, "given_name")
              )
            }
          >
            {user.Enabled ? "Disable User" : "Enable User"}
          </Button>
          <Button
            type="primary"
            onClick={() => handleUpdateUser(user)}
            style={{ marginLeft: 8, backgroundColor: "#dd2839" }}
          >
            Update User
          </Button>
        </div>
      ),
    },
  ];

  const clientColumns = [
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone_number",
      key: "phone_number",
    },
    {
      title: "Status",
      key: "status",
      render: (client: Client) => (
        <span
          className={client.is_verified ? "text-green-600" : "text-red-600"}
        >
          {client.is_verified ? "Verified" : "Not Verified"}
        </span>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: "flex", marginTop: "20px", marginLeft: "85px" }}>
        <span style={{ marginTop: "5px", marginRight: "10px" }}>Search:</span>
        <Input
          placeholder="Search by first name"
          value={searchText}
          onChange={handleSearch}
          prefix={<span className="text-gray-400">🔍</span>}
          style={{ width: "300px" }}
          allowClear
        />
      </div>
      <div
        style={{
          backgroundColor: "white",
          margin: "40px 85px",
          borderRadius: "10px",
        }}
      >
        {loading ? (
          <div style={{ margin: "40px 85px" }}>
            <Loading />
          </div>
        ) : (
          <Table
            dataSource={filteredUsers}
            columns={columns}
            rowKey="Username"
          />
        )}

        <Modal
          title="Update User Details"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <Form form={form} onFinish={updateUser}>
            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "Please input the first name!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Please input the last name!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Role"
              name="role"
            
            >
              
              <Input disabled className="bg-gray-100" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                style={{ backgroundColor: "#dd2839" }}
              >
                Update
              </Button>
            </Form.Item>
          </Form>
        </Modal>

        {/* Clients List Modal */}
        <Modal
          title="Client List"
          visible={isClientModalVisible}
          onCancel={() => setIsClientModalVisible(false)}
          footer={null}
          width={1000}
        >
          <Table
            dataSource={selectedUserClients}
            columns={clientColumns}
            rowKey="id"
            pagination={{
              pageSize: 5,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} clients`,
            }}
          />
        </Modal>
      </div>
    </div>
  );
};

export default UserList;
