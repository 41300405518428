import React, { useState } from "react";
import { Modal, Form, Input, message } from "antd";

interface TOTPModalProps {
  isVisible: boolean;
  onVerify: (code: string) => void;
  onCancel: () => void;
}

export const TOTPVerificationModal: React.FC<TOTPModalProps> = ({
  isVisible,
  onVerify,
  onCancel,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      onVerify(values.totpCode);
      form.resetFields();
    } catch (err) {
      message.error("Please enter a valid 6-digit code");
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      title="Two-Factor Authentication"
      open={isVisible}
      onOk={handleSubmit}
      onCancel={handleCancel}
      confirmLoading={loading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="totpCode"
          label="Enter your 6-digit authentication code"
          rules={[
            {
              required: true,
              message: "Please enter your authentication code",
            },
            { len: 6, message: "Code must be exactly 6 digits" },
            { pattern: /^\d+$/, message: "Code must contain only numbers" },
          ]}
        >
          <Input
            maxLength={6}
            placeholder="Enter code"
            autoComplete="off"
            onPressEnter={handleSubmit}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};
