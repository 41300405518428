import React from "react";
import { Layout } from "antd";
import RegisterUserForm from "./RegisterUserForm";
const App: React.FC = () => {
  return (
    <Layout style={{padding:'30px', backgroundColor:'white',margin: '40px 85px', borderRadius:'10px' }} >

      <RegisterUserForm />
    </Layout>
  );
};

export default App;