import { Button, Card, Col, DatePicker, Drawer, Row, Select, Typography, Table, Popconfirm, Modal, message, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import InputField from '../InputField';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ColumnsType, ColumnType } from 'antd/es/table';
import { AccountContext } from "../Admin/Accounts";
import axios from 'axios';

const StyledCard = styled(Card)`
    .ant-card-body {
        padding: 0px
    }
`

const StyledTableCard = styled(Card)`
    .ant-card-body {
        padding: 10px 10px
    }
`

interface AccountProfile {
    client_id: string;
    account_type: string;
    account_status: string;
    opening_date: string;
    initial_deposit: string;
    currency: string;
    branch_id: number;
    id:string;
  }

interface ClientDetailsProps {
    title: string;
    open: boolean;
    onClose: () => void;
    selectedClient: any; // Replace `any` with a proper type if available
    isEditing: boolean;
    onSave: () => void;
    onEditClick: () => void;
    setSelectedClient: (client: any) => void;
}

const ClientDetailsDrawer: React.FC<ClientDetailsProps> = ({
    title,
    open,
    onClose,
    selectedClient,
    isEditing,
    onSave,
    onEditClick,
    setSelectedClient
}) => {

    const accountContext = useContext(AccountContext);
    const [token, setToken] = useState<string | null>(null);
    const [agentId, setAgentId] = useState<string | null>(null);
    const [dataSource, setDataSource] = useState<AccountProfile[]>([]);
    const [columns, setColumns] = useState<ColumnsType<AccountProfile>>([]);
    const [isCreateAccountModalOpen, setIsCreateAccountModalOpen] = useState(false);
    const [accountType, setAccountType] = useState('')
    const [accountStatus, setAccountStatus] = useState('')
    const [initialDeposit, setInitialDeposit] = useState('')
    const [currency, setCurrency] = useState('')
    const [branchId, setBranchId] = useState(0)
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    useEffect(() => {
        const getAuthToken = async () => {
          if (!accountContext?.getSession) {

            return;
          }
    
          try {

            const session = await accountContext.getSession();

            //get token
            const authToken = session.token;

            //Get agent Id
            const agentId = await accountContext.getUserId();
            setAgentId(agentId);
            setToken(authToken);
            // console.log("token", authToken)
            console.log("agentId", agentId)
          } catch (error) {
            console.error("Error getting auth token:", error);

            message.error("Failed to get authentication token");
          } finally {
 
          }
        };
    
        getAuthToken();
      }, [accountContext]);

    // Function to handle updating client details
    const handleUpdateClient = async () => {
        if (!token || !selectedClient || !selectedClient.id) {
            message.error("Client information is incomplete");
            return;
        }

        try {
            // Prepare the payload for the PUT request
            const payload = {
                first_name: selectedClient.first_name || "",
                last_name: selectedClient.last_name || "",
                date_of_birth: selectedClient.date_of_birth || "",
                gender: selectedClient.gender || "",
                email: selectedClient.email || "",
                phone_number: selectedClient.phone_number || "",
                address: selectedClient.address || "",
                city: selectedClient.city || "",
                state: selectedClient.state || "",
                country: selectedClient.country || "",
                postal_code: selectedClient.postal_code || "",
                agent_id: agentId || "", // Assuming agentId is available in the state
            };

            // API call to update client details
            const response = await axios.put(
                `${BASE_URL}/clients/${selectedClient.id}`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                message.success("Client details updated successfully");
                onSave(); // Optional: trigger any additional save logic, if necessary
            } else {
                message.error("Failed to update client details");
            }
        } catch (error) {
            console.error("Error updating client details:", error);
            message.error("An error occurred while updating client details");
        }
    };

    // API call to get accounts under the client
      useEffect(() => {
        setDataSource([])
        const fetchData = async () => {
          if (!token || !selectedClient || !selectedClient.id ) return;
          console.log('client id', selectedClient.id);
      
          try {
            const response = await axios.get<AccountProfile[]>(
              `${BASE_URL}/accounts/client/`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                  'client-id': selectedClient.id,
                },
              }
            );
      
            const data = response.data;
            setDataSource(data);
            console.log('Fetched accounts:', data);
      
            // Generate columns dynamically
            if (data.length > 0) {
              // Create columns array
              const dynamicColumns = [
                // First column for Account ID
                {
                  title: 'Account ID',
                  dataIndex: 'id',
                  key: 'id',
                },
                // Dynamically generate remaining columns with capitalized titles
                ...Object.keys(data[0])
                  .filter((key) => key !== 'id') // Exclude 'id' to avoid duplication
                  .map((key) => ({
                    title: capitalizeTitle(key),
                    dataIndex: key as keyof AccountProfile,
                    key: key,
                  })),
              ];
      
              setColumns(dynamicColumns);
            }
          } catch (error: any) {
            if (error.response.status === 404) {
                message.info('No accounts found for this client')
            } else {
                console.error('Error fetching data:', error);
                message.error('Failed to fetch account data');
            }
          }
        };
      
        fetchData();
      }, [token, selectedClient, isCreateAccountModalOpen]);
      
      // Helper function to capitalize title
      const capitalizeTitle = (title: string) => {
        return title
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };

      const actionColumn: ColumnType<AccountProfile>  = {
        title: 'Action',
        key: 'action',
        fixed: 'right',
        width: 120,
        render: (_: any, record: any) => {
                return (
                    <Popconfirm
                    title="Are you sure you want to delete this account?"
                    onConfirm={() => handleDeleteClick(record.id)} 
                    onCancel={() => message.info('Delete action canceled.')}  
                    okText="Yes"
                    cancelText="No"
                    okButtonProps={{
                        style: {
                            backgroundColor: '#dd2839',
                        }
                    }}
                  >
            
                        <Button 
                            type="link"
                            style={{ color: '#dd2839', fontWeight:'bold' }}
                        >
                            Delete
                        </Button>
                    </Popconfirm>
                );
            
        },
    };

    const finalColumns = [...columns, actionColumn]

    // Delete handler function
    const handleDeleteClick = async (accountId: string) => {
        try {
        await axios.delete(`${BASE_URL}/accounts/${accountId}`, {
            headers: {
            Authorization: `Bearer ${token}`,
            },
        });
    
        // After deletion, update the dataSource by removing the deleted account
        setDataSource((prevData) => prevData.filter((account) => account.id !== accountId));
        message.success('Account deleted successfully');
        } catch (error: any) {
        console.error('Error deleting account:', error);
        message.error('Failed to delete account');
        }
    };

    const handleModalClose = () => {
        setIsCreateAccountModalOpen(false);
        setSelectedClient(null);
    };

    const handleAccountCreation = async () => {
        // Check if necessary data is available
        if (!token || !selectedClient || !selectedClient.id) {
            message.error("Client information is incomplete");
            return;
        }
    
        // Prepare the payload for account creation
        const payload = {
            client_id: selectedClient.id, // Ensure selectedClient contains the correct client id
            account_type: accountType, // Account type from state
            account_status: accountStatus, // Account status from state
            opening_date: dayjs().format('YYYY-MM-DD'), // Use current date for opening date, or modify as needed
            initial_deposit: initialDeposit, // Initial deposit from state
            currency: currency, // Currency from state
            branch_id: branchId, // Branch ID from state
        };

        console.log('payload: ', payload)
    
        try {
            // Send POST request to create account
            const response = await axios.post(
                `${BASE_URL}/accounts`,
                payload,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            // Check if the account was created successfully
            if (response.status === 200 || response.status === 201) {
                message.success('Account created successfully');
                setIsCreateAccountModalOpen(false); // Close modal after successful creation
            } else {
                message.error('Failed to create account');
            }
        } catch (error: any) {
            console.error('Error creating account:', error);
            message.error('An error occurred while creating the account');
        }
    };    

    const handleAccountTypeChange = (val: string) => {
        setAccountType(val);
    }

    const handleAccountStatusChange = (val: string) => {
        setAccountStatus(val);
    }

    const handleDepositChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setInitialDeposit(event.target.value);
    }

    const handleCurrencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setCurrency(event.target.value);
    }   

    const handleBranchIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        
        // Convert the value to a number (parse it as an integer)
        const parsedValue = parseInt(value, 10);
    
        // Check if the parsed value is a valid number
        if (!isNaN(parsedValue)) {
            setBranchId(parsedValue); // Set the value as a number
        } else {
            // Optionally, handle the case where the input is not a valid number
            setBranchId(0); // Or you could set it to `null` or some default value
        }
    };
    
    return (
        <Drawer
            title={title}
            placement="right"
            onClose={onClose}
            open={open}
            width="80%"
        >
            <Col style={{ padding: '20px 20px' }} xs={24}>
                <StyledCard 
                    title="Personal Information"
                    extra={
                        isEditing? (
                            <Button onClick={handleUpdateClient} >
                                Save 
                            </Button>
                        ) : (
                            <EditOutlined onClick={onEditClick}/>
                        )
                    }
                >
                    <Col style={{ padding: '30px 30px' }} xs={24}>
                        {/* First row: firstname, lastname, dob */}
                        <Row style={{ marginBottom: '20px' }} gutter={[8,8]}>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='First Name'
                                            value={selectedClient?.first_name} 
                                            onChange={(e) => setSelectedClient({ ...selectedClient, first_name: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>First Name</Typography>
                                            <Typography>{selectedClient?.first_name}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='Last Name'
                                            value={selectedClient?.last_name} 
                                            onChange={(e) => setSelectedClient({ ...selectedClient, last_name: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>Last Name</Typography>
                                            <Typography>{selectedClient?.last_name}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <div>
                                            <Typography>
                                                Date of Birth
                                                <Typography.Text style={{ color:'red' }}> *</Typography.Text>
                                            </Typography>
                                            <DatePicker 
                                                onChange={(date) => setSelectedClient({ ...selectedClient, date_of_birth: date ? date.format('YYYY-MM-DD') : null })} 
                                                style={{ width: '100%' }}
                                                format="YYYY-MM-DD"
                                                disabledDate={(current) => {
                                                    const today = new Date();
                                                    const minDate = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate());
                                                    const maxDate = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());

                                                    return (
                                                        current && 
                                                        (current.isBefore(minDate) || current.isAfter(maxDate))
                                                    );
                                                }}
                                                value={selectedClient?.date_of_birth? dayjs(selectedClient?.date_of_birth, 'YYYY-MM-DD') : null}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>Date of Birth</Typography>
                                            <Typography>{selectedClient?.date_of_birth}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                        </Row>

                        {/* Second row: gender, phone number, postal code */}
                        <Row style={{ marginBottom: '20px' }} gutter={[8,8]}>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <div>
                                            <Typography>
                                                Gender
                                                <Typography.Text style={{ color:'red' }}> *</Typography.Text>
                                            </Typography>
                                            <Select
                                                placeholder='Select gender'
                                                style={{ width: '100%' }}
                                                onChange={(e) => setSelectedClient({...selectedClient, gender: e.target.value })}
                                                options={[
                                                    { value: 'Male', label: 'Male' },
                                                    { value: 'Female', label: 'Female' },
                                                    { value: 'Non-binary', label: 'Non-binary' },
                                                    { value: 'Prefer not to say', label: 'Prefer not to say' },
                                                ]}
                                                value={selectedClient?.gender}
                                            />
                                        </div>
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>Gender</Typography>
                                            <Typography>{selectedClient?.gender}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='Phone Number'
                                            value={selectedClient?.phone_number} 
                                            onChange={(e) => setSelectedClient({...selectedClient, phone_number: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>Phone Number</Typography>
                                            <Typography>{selectedClient?.phone_number}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='Postal Code'
                                            value={selectedClient?.postal_code} 
                                            onChange={(e) => setSelectedClient({...selectedClient, postal_code: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>Postal Code</Typography>
                                            <Typography>{selectedClient?.postal_code}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                        </Row>

                        {/* Third row: email */}
                        <Row style={{ marginBottom: '20px' }} gutter={[8,8]}>
                            <Col xs={24}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='Email'
                                            value={selectedClient?.email} 
                                            onChange={(e) => setSelectedClient({...selectedClient, email: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>Email</Typography>
                                            <Typography>{selectedClient?.email}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                        </Row>

                        {/* Fourth row: address */}
                        <Row style={{ marginBottom: '20px' }} gutter={[8,8]}>
                            <Col xs={24}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='Address'
                                            value={selectedClient?.address} 
                                            onChange={(e) => setSelectedClient({...selectedClient, address: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>Address</Typography>
                                            <Typography>{selectedClient?.address}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                        </Row>

                        {/* Fifth row: country, city, state */}
                        <Row style={{ marginBottom: '20px' }} gutter={[8,8]}>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='Country'
                                            value={selectedClient?.country} 
                                            onChange={(e) => setSelectedClient({ ...selectedClient, country: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>Country</Typography>
                                            <Typography>{selectedClient?.country}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='City'
                                            value={selectedClient?.city} 
                                            onChange={(e) => setSelectedClient({ ...selectedClient, city: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>City</Typography>
                                            <Typography>{selectedClient?.city}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                            <Col xs={8}>
                                <Typography>
                                    {isEditing ? (
                                        <InputField
                                            title='State'
                                            value={selectedClient?.state} 
                                            onChange={(e) => setSelectedClient({ ...selectedClient, state: e.target.value })}
                                        />
                                    ) : (
                                        <div>
                                            <Typography style={{ fontWeight:'bold' }}>State</Typography>
                                            <Typography>{selectedClient?.state}</Typography>
                                        </div>
                                    )}
                                </Typography>
                            </Col>
                        </Row>
                        </Col>
                </StyledCard>
            </Col>

            <Col style={{ padding: '20px 20px' }} xs={24}>
                <StyledTableCard
                    title="Accounts"
                    extra={
                        <Button 
                            type='primary' 
                            style={{ backgroundColor: '#dd2839', fontWeight:'bold' }}
                            onClick={() => setIsCreateAccountModalOpen(true)}
                        >
                            Add Account
                        </Button>
                    }
                >

                {/* Client Account Table */}
                <Table
                    columns={finalColumns}
                    dataSource={dataSource}
                    pagination={{ pageSize: 5 }}
                    rowKey="key"
                    scroll={{ x: 1000 }}
                />
                </StyledTableCard>
            </Col>

            {/* Add account modal */}
            <Modal
                open={isCreateAccountModalOpen}
                onCancel={() => setIsCreateAccountModalOpen(false)}
                title="Add Account"
                width={600}
                style={{ padding:'24px 24px' }}
                footer={[
                    <Button key="cancel" onClick={handleModalClose}>
                        Cancel
                    </Button>,
                    <Button 
                        key="verify" 
                        type="primary" 
                        onClick={handleAccountCreation}  // Call file upload on submit
                        style={{ backgroundColor: '#dd2839' }}
                    >
                        Create Account
                    </Button>,
                ]}
            >
                <Col xs={24}>
                    <Row gutter={[30, 30]}>
                        <Col xs={8}>
                            <Typography>
                                Account Type
                                <Typography.Text style={{ color:'red' }}> *</Typography.Text>
                            </Typography>
                            <Select
                                placeholder='Select account type'
                                style={{ width: '100%' }}
                                onChange={handleAccountTypeChange}
                                options={[
                                        { value: 'Savings', label: 'Savings' },
                                        { value: 'Checking', label: 'Checking' },
                                        { value: 'Business', label: 'Business' },
                                    ]}
                                />
                        </Col>
                        <Col xs={8}>
                            <Typography>
                                Account Status
                                <Typography.Text style={{ color:'red' }}> *</Typography.Text>
                            </Typography>
                            <Select
                                placeholder='Select account status'
                                style={{ width: '100%' }}
                                onChange={handleAccountStatusChange}
                                options={[
                                        { value: 'Active', label: 'Active' },
                                        { value: 'Inactive', label: 'Inactive' },
                                        { value: 'Pending', label: 'Pending' },
                                    ]}
                                />
                        </Col>
                        <Col xs={8}>
                            <InputField 
                                title="Initial Deposit" 
                                placeholder='Enter initial deposit' 
                                onChange={handleDepositChange}
                            />
                        </Col>
                        <Col xs={8}>
                            <InputField 
                                title="Currency" 
                                placeholder='Enter currency' 
                                onChange={handleCurrencyChange}
                            />
                        </Col>
                        <Col xs={8}>
                            <InputField 
                                title="Branch ID" 
                                placeholder='Enter branch ID' 
                                onChange={handleBranchIdChange}
                            />
                        </Col>
                    </Row>
                </Col>
            </Modal>
        </Drawer>
    );
};

export default ClientDetailsDrawer;
