import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingState = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
  
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80%',
      padding: '48px',
      backgroundColor: 'white',
      borderRadius: '10px',
      minHeight: '300px'
    }}>
      <Spin indicator={antIcon} />
      <div style={{
        fontSize: '16px',
        color: '#666',
        marginTop: '16px'
      }}>
        Loading 
      </div>
    </div>
  );
};

export default LoadingState;