import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Steps,
  message,
  Typography,
  Space,
  Row,
  Col,
  Progress
} from "antd";
import {
  MailOutlined,
  LockOutlined,
  SafetyCertificateOutlined,
  ArrowLeftOutlined,
  CheckCircleFilled,
  CloseCircleFilled
} from "@ant-design/icons";

import { sendCode, passwordReset } from "../api/user";
const { Title, Text } = Typography;

const PasswordReset: React.FC = () => {
  const [form] = Form.useForm();
  const [stage, setStage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Password requirements state
  const [requirements, setRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    match: false
  });

  // Check password requirements
  const checkRequirements = (pass: string, confirm: string) => {
    setRequirements({
      length: pass.length >= 8,
      uppercase: /[A-Z]/.test(pass),
      lowercase: /[a-z]/.test(pass),
      number: /[0-9]/.test(pass),
      special: /[!@#$%^&*]/.test(pass),
      match: pass === confirm && pass !== ""
    });
  };

  // Calculate password strength
  const calculateStrength = () => {
    const reqArray = Object.values(requirements);
    const trueCount = reqArray.filter(Boolean).length;
    return (trueCount / reqArray.length) * 100;
  };

  // Get strength color
  const getStrengthColor = (strength: number) => {
    if (strength < 33) return "#ff4d4f";
    if (strength < 66) return "#faad14";
    return "#52c41a";
  };

  const RequirementItem = ({ met, text }: { met: boolean; text: string }) => (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center', 
      gap: '8px',
      color: met ? '#52c41a' : '#666'
    }}>
      {met ? (
        <CheckCircleFilled style={{ color: '#52c41a' }} />
      ) : (
        <CloseCircleFilled style={{ color: '#ff4d4f' }} />
      )}
      <Text style={{ fontSize: '14px' }}>{text}</Text>
    </div>
  );

  const onEmailSent = async (values: { email: string }) => {
    setLoading(true);
    await sendCode(values.email);
    setLoading(false);
    setEmail(values.email.toLowerCase());
    setStage(1);
  };

  const resetPassword = async (values: {
    code: string;
    password: string;
    confirmPassword: string;
  }) => {
    const allRequirementsMet = Object.values(requirements).every(Boolean);
    
    if (!allRequirementsMet) {
      message.error("Please meet all password requirements");
      return;
    }

    try {
      const response = await passwordReset(email, values.code, values.password);
      if (response) {
        message.success("Password reset successful. Redirecting to login...");
        window.location.href = '/';
      } else {
        message.error("Password reset failed. Please try again.");
      }
    } catch (error) {
      message.error("An error occurred. Please try again.");
      console.error("Error resetting password:", error);
    } finally {
      setLoading(false);
    }
  };

  const steps = [
    {
      title: "Email",
      content: (
        <Form form={form} onFinish={onEmailSent} layout="vertical">
          <Form.Item
            name="email"
            rules={[
              { required: true, message: "Please enter your email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input
              prefix={<MailOutlined style={{ color: '#666' }} />}
              placeholder="Enter your email"
              size="large"
              style={{ height: '45px' }}
            />
          </Form.Item>
          <Form.Item>
            <Button 
              type="primary" 
              htmlType="submit" 
              loading={loading} 
              block
              style={{ 
                height: '45px', 
                backgroundColor: '#dd2839', 
                borderColor: '#dd2839',
                fontSize: '16px'
              }}
            >
              Send Verification Code
            </Button>
          </Form.Item>
        </Form>
      ),
    },
    {
      title: "Reset Password",
      content: (
        <Form form={form} onFinish={resetPassword} layout="vertical">
          <div style={{ marginBottom: '24px', textAlign: 'center' }}>
            <Text type="secondary" style={{ fontSize: '15px' }}>
              We've sent a verification code to <strong>{email}</strong>
            </Text>
          </div>
          
          <Form.Item
            name="code"
            rules={[
              { required: true, message: "Please enter the verification code" },
            ]}
          >
            <Input
              prefix={<SafetyCertificateOutlined style={{ color: '#666' }} />}
              placeholder="Enter verification code"
              size="large"
              style={{ height: '45px' }}
            />
          </Form.Item>
          
          <Form.Item
            name="password"
            rules={[{ required: true, message: "Please enter your new password" }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: '#666' }} />}
              placeholder="Enter new password"
              size="large"
              style={{ height: '45px' }}
              onChange={(e) => {
                setPassword(e.target.value);
                checkRequirements(e.target.value, confirmPassword);
              }}
            />
          </Form.Item>
          
          <Form.Item
            name="confirmPassword"
            rules={[{ required: true, message: "Please confirm your password" }]}
          >
            <Input.Password
              prefix={<LockOutlined style={{ color: '#666' }} />}
              placeholder="Confirm new password"
              size="large"
              style={{ height: '45px' }}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                checkRequirements(password, e.target.value);
              }}
            />
          </Form.Item>

          {/* Password Strength Indicator */}
          <div style={{ marginBottom: '20px' }}>
            <Text strong style={{ display: 'block', marginBottom: '8px' }}>
              Password Strength
            </Text>
            <Progress 
              percent={calculateStrength()} 
              strokeColor={getStrengthColor(calculateStrength())}
              showInfo={false}
            />
          </div>

          {/* Password Requirements */}
          <Card
            size="small"
            title="Password Requirements"
            style={{ marginBottom: '24px' }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <RequirementItem met={requirements.length} text="Minimum 8 characters" />
              <RequirementItem met={requirements.uppercase} text="At least 1 uppercase letter" />
              <RequirementItem met={requirements.lowercase} text="At least 1 lowercase letter" />
              <RequirementItem met={requirements.number} text="At least 1 number" />
              <RequirementItem met={requirements.special} text="At least 1 special character (!@#$%^&*)" />
              <RequirementItem met={requirements.match} text="Passwords match" />
            </Space>
          </Card>
          
          <Form.Item>
            <Space direction="vertical" style={{ width: '100%' }} size="middle">
              <Button 
                type="primary" 
                htmlType="submit" 
                loading={loading} 
                block
                style={{ 
                  height: '45px', 
                  backgroundColor: '#dd2839', 
                  borderColor: '#dd2839',
                  fontSize: '16px'
                }}
              >
                Reset Password
              </Button>
              <Button 
                onClick={() => setStage(0)} 
                block
                icon={<ArrowLeftOutlined />}
                style={{ 
                  height: '45px',
                  fontSize: '16px',
                  border: '1px solid #d9d9d9'
                }}
              >
                Back to Email
              </Button>
            </Space>
          </Form.Item>
        </Form>
      ),
    },
  ];

  return (
    <Row justify="center" align="middle" style={{ minHeight: '100vh', backgroundColor: '#f0f2f5' }}>
      <Col xs={22} sm={20} md={16} lg={12} xl={8}>
        <Card 
          style={{ 
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
            borderRadius: '8px'
          }}
        >
          <Title 
            level={3} 
            style={{ 
              textAlign: 'center', 
              marginBottom: '32px',
              color: '#333'
            }}
          >
            Reset Password
          </Title>
          
          <Steps
            current={stage}
            items={steps.map((item) => ({ title: item.title }))}
            style={{ 
              marginBottom: '40px',
              maxWidth: '80%',
              margin: '0 auto 40px auto'
            }}
          />

          {steps[stage].content}
        </Card>
      </Col>
    </Row>
  );
};

export default PasswordReset;