import { Col, Input, Typography } from 'antd';
import React, { useState } from 'react';

interface InputFieldProps {
    title: string;
    placeholder?: string;
    onChange?: (value: any) => void;
    defaultValue?: any;
    status?: any;
    errorMessage?: string;
    value?: any;
}

const InputField = (props: InputFieldProps) => {
    const {
        title,
        placeholder = '',
        onChange,
        defaultValue,
        status = "",
        errorMessage,
        value
    } = props;

    return (
        <Col style={{ width:'100%', padding:'0px' }} xs={24}>
            <Typography>
                {title}
                <Typography.Text style={{ color:'red' }}> *</Typography.Text>
            </Typography>
            <Input 
                placeholder={placeholder}
                style={{ width:'100%' }}
                onChange={onChange}
                defaultValue={defaultValue}
                status={status}
                value={value}
            />
            {errorMessage && 
            <Typography.Text type="danger" style={{ marginTop:'10px' }}>
                {errorMessage}
            </Typography.Text>}
        </Col>
    );
};

export default InputField;